<div class="bm-assessments">
  <div class="bm-assessments-cards">
    <div *ngFor="let card of cards | orderByPosition; trackBy: trackCard" class="bm-assessments-card">
      <div class="bm-assessments-card-header">
        <span class="bm-assessments-card-label">{{ card.name }}</span>
      </div>
      <div @charts class="bm-assessments-radar-wrapper">
        <div class="bm-assessments-radar">
          <bm-radar (updated)="onFinshedLoadingChart()" [data]="card.radarChart"></bm-radar>
        </div>
      </div>
      <bm-bars class="bm-assessments-bars" [data]="card.barsChart"></bm-bars>
    </div>
  </div>
  <div class="bm-assessments-practice-vs-performance-wrapper">
    <div>Practice vs Performance</div>
    <bm-practice-vs-performance
      *ngIf="practiceVsPerformanceChart"
      class="bm-assessments-practice-vs-performance"
      [data]="practiceVsPerformanceChart"
    ></bm-practice-vs-performance>
  </div>
</div>
