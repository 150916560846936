import { Component, OnInit, Injector } from "@angular/core";
import { BehaviorSubject, Subscription, Subject, combineLatest } from "rxjs";

import { InjectField } from "core-app/helpers/angular/inject-field.decorator";
import { I18nService } from "core-app/modules/common/i18n/i18n.service";
import { ConfirmDialogService } from "core-components/modals/confirm-dialog/confirm-dialog.service";
import { GonService } from "core-app/modules/common/gon/gon.service";
import {
  RadarChart,
  ChartEditableChange,
  ChartSeries,
} from "../charts/radar/radar.component";
import { ConsolidateDashboardService } from "./consolidate-dashboard.service";
import { debounceTime } from "rxjs/operators";

import {
  trigger,
  style,
  animate,
  transition,
  state,
  group,
} from "@angular/animations";

interface ElementWithId {
  id: string | number;
}

export const selector = "bm-consolidate-dashboard";

export interface SeriesValue extends ElementWithId {
  value: number;
}

export interface EditableChange {
  card: Card;
  event: ChartEditableChange;
}

export interface SaveAssessedData {
  assessedId: string | number;
  groupId: string | number;
  completed: boolean;
  cosolidateSeries: SeriesValue[];
}

export interface Series extends ElementWithId {
  name: string;
  values: SeriesValue[];
}

export interface Assessed extends ElementWithId {
  name: string;
  completed: boolean;
  cosolidateSeries: SeriesValue[];
}

export interface AssessedConsolidateNotCompleted extends Assessed {
  name: string;
  completed: false;
  series: Series[];
}

export interface AssessedConsolidateCompleted extends Assessed {
  name: string;
  completed: true;
}

export type AssessedData =
  | AssessedConsolidateCompleted
  | AssessedConsolidateNotCompleted;

export interface GroupIndicator extends ElementWithId {
  name: string;
  code: string;
}

export interface GroupData {
  id: string;
  name: string;
  position: number;
  indicators: GroupIndicator[];
}

export interface LoadingChart {
  groupData: GroupData;
  assessedData: AssessedData;
}

export interface Group extends ElementWithId {
  name: string;
  position: number;
}

export interface Card extends ElementWithId {
  group: Group;
  position: string;
  name: string;
  completed: boolean;
  cosolidateSeries: SeriesValue[];
  avgChart: BehaviorSubject<RadarChart>;
  errorMsg: string;
  buttonStatus: boolean;
  assessedChart?: BehaviorSubject<RadarChart>;
  isLoaded?: boolean;
  isLarge: boolean;
  isClosed: boolean;
}

interface AssessedPositionParameters {
  completed: boolean;
  name: string;
  id: string | number;
  group: Group;
  isClosed: boolean;
}

export interface KeyValue<K, V> {
  key: K;
  value: V;
}

@Component({
  templateUrl: "./consolidate-dashboard.component.html",
  styleUrls: ["./consolidate-dashboard.component.sass"],
  selector,
  providers: [ConsolidateDashboardService],
  animations: [
    trigger("charts", [
      transition(":leave", [
        style({ transform: "scale(1)", opacity: 1 }),
        animate(
          "0.3s cubic-bezier(.06,.7,1,.5)",
          style({
            opacity: 0,
            transform: "scale(0)",
          })
        ),
      ]),
    ]),
  ],
})
export class ConsolidateDashboardComponent implements OnInit {
  @InjectField() I18n: I18nService;

  consolidateURL: string;

  text: {
    button: string;
    avgSeries: string;
    editableSeries: string;
    individualChart: string;
    consolidateChart: string;
    evaluationSeries: string;
    consolidatedChart: string;
  };

  subscriptions: Subscription[] = [];
  editable: Subject<EditableChange> = new Subject();
  finishedLoadingChart: Subject<void> = new Subject();

  cards: Card[] = [];

  editableColor = "#444";
  loadingChart: LoadingChart[] = [];
  usedColors = new Map<number | string, string>();
  colors: string[] = [
    "#3641e0",
    "#eb2954",
    "#21d53c",
    "#daca29",
    "#28d5bb",
    "#219feb",
    "#9541eb",
    "#e92b9c",
    "#eb8426",
    "#98e31f",
  ];

  isShortVersion = true;
  isLoadingChart = false;
  getRandomColor(): string {
    const colorNumber =
      "000000" + Math.trunc(Math.random() * 2 ** 24).toString(16);
    return "#" + colorNumber.slice(colorNumber.length - 6);
  }

  getColor(id: number | string): string {
    const oldColor = this.usedColors.get(id);
    if (oldColor) return oldColor;

    const color = this.colors.shift() || this.getRandomColor();
    this.usedColors.set(id, color);
    return color;
  }

  private loadTranslations() {
    this.text = {
      button: this.I18n.t("js.consolidate.button"),
      avgSeries: this.I18n.t("js.consolidate.avg_series"),
      evaluationSeries: this.I18n.t("js.consolidate.evaluation_series"),
      editableSeries: this.I18n.t("js.consolidate.editable_series"),
      consolidatedChart: this.I18n.t("js.consolidate.consolidated_chart"),
      consolidateChart: this.I18n.t("js.consolidate.consolidate_chart"),
      individualChart: this.I18n.t("js.consolidate.individual_chart"),
    };
  }

  errorMsg = "";

  onFinshedLoadingChart(card: Card) {
    if (!card.isLoaded) {
      card.isLoaded = true;
      this.isLoadingChart = false;
      this.finishedLoadingChart.next();
    }
  }

  getCardPosition({
    completed,
    name,
    id,
    group,
    isClosed,
  }: AssessedPositionParameters): string {
    return `${completed ? 0 : 1}_${isClosed ? 1 : 0}_${
      group.position
    }_${name}_${id}`;
  }

  initChartLoader() {
    this.subscriptions.push(
      this.finishedLoadingChart.subscribe(
        () => {
          if (this.isLoadingChart) return;
          const chart = this.loadingChart.shift();
          if (!chart) return;
          this.isLoadingChart = true;
          const { groupData, assessedData } = chart;
          const axisLabels = groupData.indicators.map((axis) => ({
            label: axis.name,
            code: axis.code,
          }));

          const group: Group = {
            id: groupData.id,
            name: groupData.name,
            position: groupData.position,
          };

          if (assessedData.completed == true) {
            const groupColor = this.getColor(`group_${groupData.id}`);
            const consolidateChartSeries = [];
            consolidateChartSeries.push({
              id: 1,
              name: this.text.editableSeries,
              values: this.parserSeries(
                groupData,
                assessedData.cosolidateSeries
              ),
              noBackground: false,
              color: groupColor,
            });
            const position = this.getCardPosition({
              ...assessedData,
              group,
              isClosed: false,
            });

            this.cards.push({
              id: assessedData.id,
              position,
              name: assessedData.name,
              group,
              cosolidateSeries: assessedData.cosolidateSeries,
              avgChart: new BehaviorSubject<RadarChart>({
                numberOfAxis: groupData.indicators.length,
                maxLevel: 5,
                axisLabels,
                series: consolidateChartSeries,
              }),
              errorMsg: "",
              completed: assessedData.completed,
              buttonStatus: true,
              isClosed: false,
              isLarge: false,
            });
          } else {
            const consolidateChartSeries = [];
            let assessedChart:
              | undefined
              | BehaviorSubject<RadarChart> = undefined;
            const groupColor = this.getColor(`group_${groupData.id}`);
            if (assessedData.series.length > 1) {
              this.isShortVersion = false;
              const series: ChartSeries[] = [];
              for (let i = 0; i < assessedData.series.length; i++) {
                const color = this.getColor(assessedData.series[i].id);
                series.push({
                  id: i,
                  name: assessedData.series[i].name,
                  values: this.parserSeries(
                    groupData,
                    assessedData.series[i].values
                  ),
                  color,
                });
              }
              assessedChart = new BehaviorSubject<RadarChart>({
                numberOfAxis: groupData.indicators.length,
                maxLevel: 5,
                axisLabels,
                series,
              });
              const avgSeries = this.generateAvg(groupColor, series);
              if (avgSeries) consolidateChartSeries.push(avgSeries);
            } else {
              if (assessedData.series[0]) {
                consolidateChartSeries.push({
                  id: 0,
                  name: this.text.evaluationSeries,
                  values: this.parserSeries(
                    groupData,
                    assessedData.series[0].values
                  ),
                  color: groupColor,
                });
              }
            }
            consolidateChartSeries.push({
              id: 1,
              name: this.text.editableSeries,
              values: this.parserSeries(
                groupData,
                assessedData.cosolidateSeries
              ),
              noBackground: true,
              editable: true,
              color: this.editableColor,
            });
            const position = this.getCardPosition({
              ...assessedData,
              group,
              isClosed: false,
            });

            this.cards.push({
              id: assessedData.id,
              position,
              group,
              isClosed: false,
              name: assessedData.name,
              cosolidateSeries: assessedData.cosolidateSeries,
              assessedChart,
              avgChart: new BehaviorSubject<RadarChart>({
                numberOfAxis: groupData.indicators.length,
                maxLevel: 5,
                axisLabels,
                series: consolidateChartSeries,
              }),
              completed: assessedData.completed,
              errorMsg: "",
              buttonStatus: true,
              isLarge: !!assessedChart,
            });
          }
          this.sortCard();
        },
        (err) => {
          this.errorMsg = err;
        }
      )
    );
  }

  ngOnInit(): void {
    this.initEditableChange();
    this.loadTranslations();
    this.initChartLoader();
    const hash = window.location.hash;
    const urlIfError = this.gon.get("assessed_list_url") as string;

    if (!hash) {
      window.location.href = urlIfError;
    } else {
      const groupIds = hash.match(/[0-9]+(?==)/g);
      const groupContents = hash.match(/=[^&]+/g);
      if (
        !groupIds ||
        !groupContents ||
        groupIds.length !== groupContents.length
      ) {
        window.location.href = urlIfError;
        throw new Error("Invalid hash");
      }

      for (const index in groupContents) {
        const groupDataSubject = new Subject<GroupData>();

        const ids = groupContents[index].replace(/^=/g, "").split(",");
        for (const id of ids) {
          const assessedDataObservable = this.dashboardService.getAssessedData(
            groupIds[index],
            id
          );

          this.subscriptions.push(
            combineLatest([groupDataSubject, assessedDataObservable]).subscribe(
              ([groupData, assessedData]) => {
                this.loadingChart.push({
                  groupData,
                  assessedData,
                });
                this.finishedLoadingChart.next();
              }
            )
          );
        }

        this.subscriptions.push(
          this.dashboardService.getGroupData(groupIds[index]).subscribe(
            (data) => {
              groupDataSubject.next(data);
              groupDataSubject.complete();
            },
            (err) => {
              console.error(err);
            }
          )
        );
      }
    }
  }

  trackCard(index: number, card: Card) {
    return `${card.id}_${card.group.id}`;
  }

  consolidate(card: Card, event: MouseEvent) {
    (event.target as HTMLElement).blur();
    if (!card.buttonStatus) return;
    card.buttonStatus = false;
    this.subscriptions.push(
      this.dashboardService
        .saveAssessedData({
          groupId: card.group.id,
          completed: true,
          cosolidateSeries: card.cosolidateSeries,
          assessedId: card.id,
        })
        .subscribe(
          () => {
            card.errorMsg = "";
            card.buttonStatus = true;
            card.completed = true;
            card.isClosed = false;
            card.assessedChart = undefined;
            //card.position = this.getCardPosition(card);
            this.sortCard();
            const avgChart = card.avgChart.value;

            const avgSeries = avgChart.series.find((series) => series.id === 0);
            const editableSeries = avgChart.series.find(
              (series) => series.id === 1
            );
            if (avgSeries && editableSeries) {
              avgSeries.values = editableSeries.values;
              avgSeries.name = editableSeries.name;
              avgChart.series = [avgSeries];
              card.avgChart.next(avgChart);
            }
          },
          (err) => {
            card.errorMsg = err;
            card.buttonStatus = true;
          }
        )
    );
  }

  private parserSeries(groupData: GroupData, values: SeriesValue[]): number[] {
    return groupData.indicators.map((indicator) => {
      const serieValue = values.find((value) => value.id === indicator.id);
      if (serieValue === undefined)
        throw new Error("Invalid series, indicator without value.");
      return serieValue.value;
    });
  }

  initEditableChange() {
    this.subscriptions.push(
      this.editable.subscribe(({ card, event }: EditableChange) => {
        const avgChartData = card.avgChart.value;
        const series = avgChartData.series.find(
          (series) => series.id === event.series
        );
        if (series) {
          card.cosolidateSeries[event.axis].value = event.newLevel;
          series.values[event.axis] = event.newLevel;
          card.avgChart.next(avgChartData);
        }
      })
    );
    this.subscriptions.push(
      this.editable
        .pipe(debounceTime(1000))
        .subscribe(({ card }: EditableChange) => {
          this.dashboardService
            .saveAssessedData({
              groupId: card.group.id,
              completed: false,
              cosolidateSeries: card.cosolidateSeries,
              assessedId: card.id,
            })
            .subscribe(
              () => {
                card.errorMsg = "";
              },
              (err) => (card.errorMsg = err)
            );
        })
    );
  }

  generateAvg(
    color: string,
    sourceSeries: ChartSeries[]
  ): ChartSeries | undefined {
    const numberOfSeries = sourceSeries.length;
    if (numberOfSeries > 0) {
      const numberOfAxis = sourceSeries[0].values.length;

      const sums = Array(numberOfAxis).fill(0);
      for (const series of sourceSeries) {
        for (
          let indexValue = 0;
          indexValue < series.values.length;
          indexValue++
        ) {
          sums[indexValue] += series.values[indexValue];
        }
      }
      const avg = sums.map((value) => value / numberOfSeries);

      return {
        id: 0,
        name: this.text.avgSeries,
        values: avg,
        noBackground: false,
        color: color,
      };
    }
    return;
  }

  sortCard() {
    this.cards = this.cards.sort((a, b) => {
      if (a.position === b.position) return 0;
      return [a.position, b.position].sort()[0] === a.position ? -1 : 1;
    });
  }

  hashURL: string;

  /**
   * @description Close all subscriptions and behaviorSubject
   */
  ngOnDestroy() {
    for (const card of this.cards) {
      card.avgChart.complete();
      card.assessedChart?.complete();
    }

    for (const subscriptions of this.subscriptions) {
      subscriptions.unsubscribe();
    }
  }

  animCardSizeEnd(card: Card) {
    //card.isLarge = !!card.assessedChart;
  }

  constructor(
    readonly injector: Injector,
    readonly confirmDialog: ConfirmDialogService,
    readonly gon: GonService,
    private dashboardService: ConsolidateDashboardService
  ) {}

  ngAfterViewInit() {}

  trackById(index: number, item: ElementWithId) {
    return item.id;
  }
}
