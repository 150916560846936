import { Component, OnInit, Injector } from "@angular/core";
import { BehaviorSubject, Subscription, Subject, combineLatest } from "rxjs";
import { cloneDeep } from "lodash";

import { InjectField } from "core-app/helpers/angular/inject-field.decorator";
import { I18nService } from "core-app/modules/common/i18n/i18n.service";
import { ConfirmDialogService } from "core-components/modals/confirm-dialog/confirm-dialog.service";
import { GonService } from "core-app/modules/common/gon/gon.service";
import { RadarChart, ChartSeries } from "../charts/radar/radar.component";
import { BarsChart } from "../charts/bars/bars.component";
import { trigger, style, animate, transition } from "@angular/animations";
import {
  PerformanceVsPracticeSeries,
  PracticeVsPerformanceChart,
} from "../charts/practice-vs-performance/practice-vs-performance.component";
import { WindowWithFilter } from "../assessments-dashboard-filter-component/assessments-dashboard-filter.component";

interface ElementWithId {
  id: string | number;
}

export const selector = "bm-assessments-dashboard";

export interface SeriesValue extends ElementWithId {
  value: number;
}

export interface Series extends ElementWithId {
  name: string;
  values: SeriesValue[];
}

export interface AssessementSum {
  count: number;
  sum: number;
}

export interface AssessedData {
  name: string;
  assessments: SeriesValue[];
}

export interface GroupIndicator extends ElementWithId {
  reference: number;
  name: string;
  code: string;
  type: string;
}

export interface GroupData {
  id: string;
  name: string;
  position: number;
  indicators: GroupIndicator[];
  assesseds: AssessedData[];
}

export interface LoadingChart {
  groupData: GroupData;
}

export interface Group extends ElementWithId {
  name: string;
  position: number;
}

export interface Card extends ElementWithId {
  position: string;
  name: string;
  radarChart: BehaviorSubject<RadarChart>;
  barsChart: BehaviorSubject<BarsChart>;
  errorMsg: string;
}

export interface KeyValue<K, V> {
  key: K;
  value: V;
}

@Component({
  templateUrl: "./assessments-dashboard.component.html",
  styleUrls: ["./assessments-dashboard.component.sass"],
  selector,
  animations: [
    trigger("charts", [
      transition(":leave", [
        style({ transform: "scale(1)", opacity: 1 }),
        animate(
          "0.3s cubic-bezier(.06,.7,1,.5)",
          style({
            opacity: 0,
            transform: "scale(0)",
          })
        ),
      ]),
    ]),
  ],
})
export class AssessmentsDashboardComponent implements OnInit {
  @InjectField() I18n: I18nService;

  consolidateURL: string;

  text: {
    button: string;
    avgSeries: string;
    editableSeries: string;
    individualChart: string;
    consolidateChart: string;
    evaluationSeries: string;
    referenceSeries: string;
    consolidatedChart: string;
  };
  indicatorTypes = new Set<string>();
  maxScore: number;
  groups: GroupData[];
  subscriptions: Subscription[] = [];
  finishedLoadingChart: Subject<void> = new Subject();

  cardsDefault: Card[] = [];
  cards: Card[] = [];
  practiceVsPerformanceChart = new Subject<PracticeVsPerformanceChart>();
  referenceColor = "#227f10";
  loadingChart: LoadingChart[] = [];
  usedColors = new Map<number | string, string>();
  practiceVsPerformanceChartDefault: PracticeVsPerformanceChart;
  colors: string[] = [
    "#3641e0",
    "#eb2954",
    "#daca29",
    "#28d5bb",
    "#219feb",
    "#9541eb",
    "#e92b9c",
    "#eb8426",
    "#98e31f",
  ];

  isShortVersion = true;
  isLoadingChart = false;
  getRandomColor(): string {
    const colorNumber = "000000" + Math.trunc(Math.random() * 2 ** 24).toString(16);
    return "#" + colorNumber.slice(colorNumber.length - 6);
  }

  getColor(id: number | string): string {
    const oldColor = this.usedColors.get(id);
    if (oldColor) return oldColor;

    const color = this.colors.shift() || this.getRandomColor();
    this.usedColors.set(id, color);
    return color;
  }

  onFinshedLoadingChart(card: Card) {
    this.isLoadingChart = false;
    this.finishedLoadingChart.next();
  }

  private loadTranslations() {
    this.text = {
      button: this.I18n.t("js.consolidate.button"),
      avgSeries: this.I18n.t("js.consolidate.avg_series"),
      evaluationSeries: this.I18n.t("js.consolidate.evaluation_series"),
      referenceSeries: this.I18n.t("js.consolidate.reference_series"),
      editableSeries: this.I18n.t("js.consolidate.editable_series"),
      consolidatedChart: this.I18n.t("js.consolidate.consolidated_chart"),
      consolidateChart: this.I18n.t("js.consolidate.consolidate_chart"),
      individualChart: this.I18n.t("js.consolidate.individual_chart"),
    };
  }

  errorMsg = "";

  initChartLoader() {
    this.subscriptions.push(
      this.finishedLoadingChart.subscribe(
        () => {
          if (this.isLoadingChart) return;
          const chart = this.loadingChart.shift();
          if (!chart) return;
          this.isLoadingChart = true;
          const { groupData } = chart;
          const axisLabels = groupData.indicators.map((axis) => ({
            label: axis.name,
            code: [axis.code, axis.type].join("\n"),
          }));

          const barsSeries = [];

          const values = this.calcIndicatorTypeAverage(
            groupData.indicators,
            groupData.indicators.map((indicator) => ({
              id: indicator.id,
              value: indicator.reference,
            }))
          );

          barsSeries.push({
            id: -1,
            name: this.text.referenceSeries,
            values,
            color: this.referenceColor,
          });

          for (let index = 0; index < groupData.assesseds.length; index++) {
            const assessed = groupData.assesseds[index];
            const color = this.getColor(`${index}-${assessed.name}`);

            const values = this.calcIndicatorTypeAverage(groupData.indicators, assessed.assessments);
            barsSeries.push({
              id: index,
              name: assessed.name,
              values,
              color,
            });
          }
          const barsChart = {
            numberOfAxis: this.indicatorTypes.size,
            axisLabels: Array.from(this.indicatorTypes)
              .sort()
              .map((type) => ({
                code: type,
              })),
            series: barsSeries,
          };

          const radarChart = {
            numberOfAxis: groupData.indicators.length,
            maxLevel: this.maxScore,
            axisLabels,
            series: [
              {
                id: -1,
                name: this.text.referenceSeries,
                values: groupData.indicators.map((indicator) => indicator.reference),
                color: this.referenceColor,
              },

              ...groupData.assesseds.map((assessed, index) => ({
                id: index,
                name: assessed.name,
                values: this.parserSeries(groupData, assessed.assessments),
                color: this.getColor(`${index}-${assessed.name}`),
              })),
            ],
          };

          const card = {
            id: groupData.id,
            position: groupData.position + "_" + groupData.name,
            name: groupData.name,
            barsChart: new BehaviorSubject<BarsChart>(barsChart),
            radarChart: new BehaviorSubject<RadarChart>(radarChart),
            errorMsg: "",
          };

          this.cards.push(card);
          this.cardsDefault.push(cloneDeep(card));
          this.loadPracticeVsPerformanceChart();
        },
        (err) => {
          this.errorMsg = err;
        }
      )
    );
  }

  private calcIndicatorTypeAverage(indicators: GroupIndicator[], seriesValues: SeriesValue[]) {
    const sums = new Map<string, AssessementSum>();
    for (const serieValue of seriesValues) {
      const indicator = indicators.find((indicator) => indicator.id === serieValue.id);
      if (!indicator) throw new Error("Invalid assessment, assessment indicator not found.");

      const current = sums.get(indicator.type) || {
        sum: 0,
        count: 0,
      };
      sums.set(indicator.type, {
        sum: current.sum + serieValue.value,
        count: current.count + 1,
      });
    }
    const values: number[] = [];
    for (const type of Array.from(sums.keys()).sort()) {
      const { sum, count } = sums.get(type) as AssessementSum;
      values.push(sum / count / this.maxScore);
    }
    return values;
  }

  loadSidebarFilter() {
    const dashboardFilter = (window as WindowWithFilter).br?.edu?.ifsc?.benchmarking?.dashboardFilter;
    if (dashboardFilter) {
      dashboardFilter.addListener((event) => {
        if (event.type === "updatedItem") {
          const allowedNames = event.list.filter((item) => item.status).map((item) => item.name);
          for (let i = 0; i < this.cards.length; i++) {
            const cardDefault = this.cardsDefault[i];
            const seriesNewRadar = cardDefault.radarChart.value.series.filter((series) =>
              allowedNames.includes(series.name)
            );
            this.cards[i].radarChart.next({
              ...cardDefault.radarChart.value,
              series: seriesNewRadar,
            });
            const seriesNewBars = cardDefault.barsChart.value.series.filter((series) =>
              allowedNames.includes(series.name)
            );
            this.cards[i].barsChart.next({
              ...cardDefault.barsChart.value,
              series: seriesNewBars,
            });
          }
          this.practiceVsPerformanceChart.next({
            ...this.practiceVsPerformanceChartDefault,
            series: this.practiceVsPerformanceChartDefault.series.filter((series) =>
              allowedNames.includes(series.name)
            ),
          });
        }
      });
    }
  }
  ngOnInit(): void {
    this.loadTranslations();
    this.loadIndicatorTypes();
    this.initChartLoader();
    this.loadPracticeVsPerformanceChart();
    this.loadSidebarFilter();
    for (const group of this.groups) {
      this.loadingChart.push({
        groupData: group,
      });
      this.finishedLoadingChart.next();
    }
  }

  loadPracticeVsPerformanceChart() {
    const allSeries: PerformanceVsPracticeSeries[] = [];
    for (const card of this.cardsDefault) {
      const allSeriesCard = card.barsChart.value.series;
      for (const seriesCard of allSeriesCard) {
        const series = allSeries.find((series) => series.id === seriesCard.id);
        if (!series) {
          allSeries.push({
            ...seriesCard,
            values: [...seriesCard.values],
            lineFormat: seriesCard.id === -1,
          });
        } else {
          for (let i = 0; i < seriesCard.values.length; i++) {
            series.values[i] += seriesCard.values[i];
          }
        }
      }
    }
    const series: PerformanceVsPracticeSeries[] = allSeries.map((series) => {
      return {
        ...series,
        values: series.values.map((value) => value / this.cardsDefault.length),
      };
    });
    const chart = {
      maxLevel: 1,
      axisLabels: Array.from(this.indicatorTypes),
      series,
    };
    this.practiceVsPerformanceChart.next(chart);
    this.practiceVsPerformanceChartDefault = cloneDeep(chart);
  }

  loadIndicatorTypes() {
    for (const group of this.groups) {
      for (const indicator of group.indicators) {
        this.indicatorTypes.add(indicator.type);
      }
    }
  }

  trackCard(index: number, card: Card) {
    return `${card.position}_${card.name}`;
  }

  private parserSeries(groupData: GroupData, values: SeriesValue[]): number[] {
    return groupData.indicators.map((indicator) => {
      const serieValue = values.find((value) => value.id === indicator.id);

      if (serieValue === undefined) throw new Error("Invalid series, indicator without value.");
      return serieValue.value;
    });
  }

  /**
   * @description Close all subscriptions and behaviorSubject
   */
  ngOnDestroy() {
    for (const card of this.cardsDefault) {
      card.radarChart.complete();
      card.barsChart.complete();
    }

    for (const subscriptions of this.subscriptions) {
      subscriptions.unsubscribe();
    }
  }

  constructor(readonly injector: Injector, readonly confirmDialog: ConfirmDialogService, readonly gon: GonService) {
    this.maxScore = this.gon.get("max_score") as number;
    this.groups = this.gon.get("groups") as GroupData[];
  }

  trackById(index: number, item: ElementWithId) {
    return item.id;
  }
}
