import { InjectField } from "core-app/helpers/angular/inject-field.decorator";
import { I18nService } from "core-app/modules/common/i18n/i18n.service";
import { Component, Injector, Input } from "@angular/core";
import { Injectable } from "@angular/core";
import { Observable, Subscription } from "rxjs";
import { ElementWithId } from "../../questions-component/interfaces";

export interface ChartLegend extends ElementWithId {
  name: string;
  color: string;
}

@Injectable()
@Component({
  selector: "bm-legend",
  templateUrl: "./legend.component.html",
  styleUrls: ["./legend.component.sass"],
})
export class LegendComponent {
  @Input() series: Observable<ChartLegend[]>;

  @InjectField() I18n: I18nService;

  constructor(readonly injector: Injector) {}

  trackById(index: number, item: ChartLegend): number {
    return item.id;
  }
}
