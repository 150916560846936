<div class="bm-legend">
  <div
    class="bm-legend-item"
    *ngFor="let item of series | async; trackBy: trackById"
  >
    <svg width="8px" height="8px" xmlns="http://www.w3.org/2000/svg">
      <circle cx="4" cy="4" r="4" [attr.fill]="item.color"></circle>
    </svg>
    <span class="bm-legend-item-name"
      >{{ item.name }}</span
    >
  </div>
</div>
