import { Component, OnInit, Injector } from "@angular/core";
import { InjectField } from "core-app/helpers/angular/inject-field.decorator";
import { I18nService } from "core-app/modules/common/i18n/i18n.service";
import { ConfirmDialogService } from "core-components/modals/confirm-dialog/confirm-dialog.service";
import { GonService } from "core-app/modules/common/gon/gon.service";

import { QuestionsService } from "./questions.service";
import {
  Answer,
  ElementWithId,
  SurveyIndicator,
  SurveyData,
} from "./interfaces";

export const selector = "bm-questions";

@Component({
  templateUrl: "./questions.component.html",
  styleUrls: ["./questions.component.scss"],
  selector,
  providers: [QuestionsService],
})
export class QuestionsComponent implements OnInit {
  @InjectField() I18n: I18nService;

  saveURL: string;
  nextURL: string;
  indicators: SurveyIndicator[] = [];

  text: {
    save: string;
    assessingMSG: string;
  };

  error: string;
  survey: SurveyData;
  showBlankAssessmentAlert: boolean = false;

  constructor(
    readonly injector: Injector,
    private questionsService: QuestionsService,
    readonly confirmDialog: ConfirmDialogService,
    readonly gon: GonService
  ) {}

  ngOnInit(): void {
    this.indicators = this.gon.get("indicators") as SurveyIndicator[];
    this.survey = this.gon.get("survey") as SurveyData;
    this.saveURL = this.gon.get("save_url") as string;
    this.nextURL = this.gon.get("next_url") as string;

    this.text = {
      save: this.I18n.t("js.button_save"),
      assessingMSG: this.I18n.t("js.label_assessing", {
        assessed: this.survey.assessed.name,
      }),
    };
  }

  async saveAnswers() {
    const answers: Answer[] = this.indicators.map((indicator) => ({
      indicatorId: indicator.id,
      noteId: indicator.noteSelected,
    }));
    const isCompletelyMarked = !answers.some(
      (answer) => answer.noteId === null
    );
    try {
      if (!isCompletelyMarked) {
        await this.confirmDialog.confirm({
          text: {
            text: this.I18n.t("js.modals.save_partial_assessment.text"),
            title: this.I18n.t("js.modals.save_partial_assessment.title"),
          },
          closeByEscape: true,
          showClose: true,
          closeByDocument: true,
          dangerHighlighting: false,
        });
      }

      this.questionsService
        .saveAnswers(this.saveURL, {
          assessedId: this.survey.assessed.id,
          groupId: this.survey.group.id,
          answers,
        })
        .subscribe(
          () => {
            window.location.href = this.nextURL;
          },
          (error) => (this.error = error)
        );
    } catch (err) {
      this.showBlankAssessmentAlert = true;
    }
  }

  trackById(index: number, item: ElementWithId) {
    return item.id;
  }
}
