<div class="toolbar-container">
  <div class="toolbar">
    <div class="title-container bm-toolbar-title">
      <h2>{{ text.title }}</h2>
    </div>
    <ul class="toolbar-items">
      <li class="toolbar-item">
        <a
          [href]="buttonURL"
          class="button bm-button"
          [ngClass]="{
            'bm-button-disabled': !buttonURL,
            '-alt-highlight': buttonURL
          }"
          ><span class="button--text">{{ text.button }}</span></a
        >
      </li>
    </ul>
  </div>
  <h3>{{ text.description }}</h3>
</div>

<div class="bm-checklists">
  <div class="bm-group" *ngFor="let group of groupsWithStatus">
    <div class="bm-group-name">
      <input
        #groupCheckbox
        class="bm-checkbox"
        [id]="'bm-checklist-group-' + group.id"
        type="checkbox"
        (click)="onGroupCheckboxClick(group)"
        [disabled]="group.disabled"
      />
      <label [for]="'bm-checklist-group-' + group.id" class="bm-group-label">{{ group.name }}</label>
    </div>
    <div class="bm-group-content">
      <div class="bm-assessed" *ngFor="let assessed of group.assesseds; trackBy: trackById">
        <div class="bm-assessed-name">
          <input
            (click)="onAssessedCheckboxClick($event, assessed, group)"
            class="bm-checkbox"
            [id]="'bm-checklist-' + group.id + '-' + assessed.id"
            type="checkbox"
            [checked]="assessed.checkboxStatus"
            [disabled]="assessed.disabled"
          />
          <user-avatar
            [attr.data-user-name]="assessed.name"
            [attr.data-id]="assessed.id"
            data-class-list="avatar"
            data-use-fallback="true"
          ></user-avatar>
          <label class="bm-assessed-label" [for]="'bm-checklist-' + group.id + '-' + assessed.id">{{
            assessed.name
          }}</label>
        </div>
        <div *ngIf="assessed.status === 'Consolidated'" class="bm-assessor">
          <div class="bm-checkbox-wrapper"></div>
          <span class="bm-assessor-name">{{ text.consolidated }}</span>
        </div>
        <div class="bm-assessor" *ngFor="let assessor of assessed.assessors; trackBy: trackById">
          <div class="bm-checkbox-wrapper"></div>
          <span class="bm-assessor-name">{{ assessor.count }}/{{ group.count }} {{ assessor.name }}</span>
        </div>
      </div>
    </div>
  </div>
</div>
