<div class="bm-indicator" [ngClass]="{ 'bm-error': error }">
  <span class="bm-indicator-number">{{ index }}</span>
  <div class="bm-indicator-content">
    <div class="bm-indicator-name">{{ indicator.name }}</div>
    <div class="bm-indicator-description" [title]="indicator.description">
      {{ indicator.description }}
    </div>
    <div>
      <div
        class="bm-indicator-note"
        *ngFor="let note of indicator.notes; trackBy: trackById"
        [ngClass]="{ 'bm-note-active': indicator.noteSelected === note.id }"
        (click)="onNoteClick(note.id)"
      >
        <div class="bm-note-score">{{ note.value }}</div>
        <div class="bm-note-description">{{ note.description }}</div>
      </div>
    </div>
  </div>
</div>
