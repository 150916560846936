import { Observable } from 'rxjs'
import { isEqual, cloneDeep } from 'lodash';


export const deepDistinct = <T>() => (source: Observable<T>): Observable<T> => {
    let beforeValue: T | undefined
    return new Observable<T>(subscriber => {
        return source.subscribe({
            next(value) {
                if (beforeValue === undefined || !isEqual(value, beforeValue)) {
                    beforeValue = cloneDeep(value)
                    subscriber.next(value);
                }
            },
            error(error) {
                beforeValue = undefined
                subscriber.error(error);
            },
            complete() {
                beforeValue = undefined
                subscriber.complete();
            }
        });
    });

}

