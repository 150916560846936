import { Observable, BehaviorSubject, Subscription, combineLatest, Subject } from "rxjs";

import { debounceTime } from "rxjs/operators";
import { deepDistinct } from "../libs/operators/deep-distinct";
import {
  Component,
  Input,
  SimpleChanges,
  ViewChildren,
  Output,
  EventEmitter,
  QueryList,
  ElementRef,
  ViewChild,
} from "@angular/core";

interface Position {
  x: number;
  y: number;
}

interface OneDimensionPos {
  start: number;
  size: number;
}

interface SliceHorizontalCoords {
  x: number;
  width: number;
}

interface SliceVericalCoords {
  y: number;
  height: number;
}

interface ElementWithId {
  id: number;
}

interface PlotDot extends Position {
  value: number;
  axis: number;
}

interface PositionWithTextIndex extends Position {
  text: string[];
  index: number;
}

interface PlotSeries extends ElementWithId, Position {
  name: string;
  path: string;
  color: string;
}
interface FloatingLabel extends Position {
  series: PlotSeries[];
}

export interface PerformanceVsPracticeSeries {
  id: number;
  name: string;
  values: number[];
  lineFormat?: boolean;
  color: string;
}

export interface PracticeVsPerformanceChart {
  maxLevel: number;
  axisLabels: string[];
  series: PerformanceVsPracticeSeries[];
}

export interface SliceArea extends Position {
  width: number;
  height: number;
}

export interface Area {
  x: number;
  y: number;
  width: number;
  height: number;
  name: string;
  opacity: number;
}
export interface PlotArea extends Area {
  labelX: number;
  labelY: number;
}

export interface ChartEditableChange {
  series: number;
  axis: number;
  newLevel: number;
}

@Component({
  selector: "bm-practice-vs-performance",
  templateUrl: "./practice-vs-performance.component.html",
  styleUrls: ["./practice-vs-performance.component.sass"],
})
export class PracticeVsPerformanceComponent {
  constructor() {}

  @ViewChild("svg") svgElement: ElementRef;
  @ViewChild("groupChart") groupChart: ElementRef;

  @ViewChildren("path") pathElements: QueryList<ElementRef>;
  @ViewChildren("pathBlur")
  pathBlurElements: QueryList<ElementRef>;

  @Output() editableChange = new EventEmitter<ChartEditableChange>();
  @Output() updated = new EventEmitter<void>();
  @Input() data: Observable<PracticeVsPerformanceChart>;

  dataSubscription: Subscription | null;
  subscriptions: Subscription[] = [];

  backgroundBaseSize: number = 0;
  backgroundLevelSpace: number = 30;

  maxLevel = new BehaviorSubject<number>(0);
  axisLabels = new BehaviorSubject<string[]>([]);
  series = new BehaviorSubject<PerformanceVsPracticeSeries[]>([]);
  moveEventObserver = new Subject<MouseEvent | TouchEvent>();
  outEventObserver = new Subject<PlotSeries | null>();
  moveLabelEventObserver = new Subject<PositionWithTextIndex>();
  outLabelEventObserver = new Subject<null>();
  editableEventObserver = new Subject<ChartEditableChange>();
  cursorOnLabel = false;
  plotAreas: PlotArea[] = [];
  areas: Area[] = [
    /*{
      x: 0,
      y: 0,
      width: 33.3,
      height: 33.3,
      name: "1",
      opacity: 0,
    },
    {
      x: 33.3,
      y: 0,
      width: 33.3,
      height: 33.3,
      name: "2",
      opacity: 0.06,
    },
    {
      x: 66.6,
      y: 0,
      width: 33.3,
      height: 33.3,
      name: "3",
      opacity: 0.12,
    },
    {
      x: 0,
      y: 33.3,
      width: 33.3,
      height: 33.3,
      name: "2",
      opacity: 0.06,
    },
    {
      x: 33.3,
      y: 33.3,
      width: 33.3,
      height: 33.3,
      name: "3",
      opacity: 0.12,
    },
    {
      x: 0,
      y: 66.6,
      width: 33.3,
      height: 33.3,
      name: "3",
      opacity: 0.12,
    },
    {
      x: 33.3,
      y: 66.6,
      width: 33.3,
      height: 33.3,
      name: "4",
      opacity: 0.18,
    },
    {
      x: 66.6,
      y: 66.6,
      width: 33.3,
      height: 33.3,
      name: "5",
      opacity: 0.24,
    },
    {
      x: 66.6,
      y: 33.3,
      width: 33.3,
      height: 33.3,
      name: "4",
      opacity: 0.18,
    },
    {
      x: 0,
      y: 60,
      width: 60,
      height: 40,
      name: "Vulneráveis",
      opacity: 0,
    },
    {
      x: 0,
      y: 0,
      width: 60,
      height: 60,
      name: "Contrapesos",
      opacity: 0.06,
    },

    {
      x: 0,
      y: 0,
      width: 50,
      height: 50,
      name: "Saco de pancadas",
      opacity: 0.06,
    },
    {
      x: 60,
      y: 60,
      width: 40,
      height: 40,
      name: "Desafiadores",
      opacity: 0.06,
    },
    {
      x: 80,
      y: 80,
      width: 20,
      height: 20,
      name: "Classe mundial",
      opacity: 0.06,
    },
    {
      x: 60,
      y: 0,
      width: 40,
      height: 60,
      name: "Promissores",
      opacity: 0,
    }, */
    {
      x: 0,
      y: 60,
      width: 60,
      height: 40,
      name: "Enigmática",
      opacity: 0.01,
    },
    {
      x: 0,
      y: 0,
      width: 60,
      height: 60,
      name: "Funcional", //Funcional (burocrática, padronizada, mediana)
      opacity: 0.08,
    },
    {
      x: 0,
      y: 0,
      width: 50,
      height: 50,
      name: "Embrionária",
      opacity: 0.08,
    },
    {
      x: 60,
      y: 60,
      width: 40,
      height: 40,
      name: "Operacional",
      opacity: 0.08,
    },
    {
      x: 80,
      y: 80,
      width: 20,
      height: 20,
      name: "Otimizada",
      opacity: 0.08,
    },
    {
      x: 60,
      y: 0,
      width: 40,
      height: 60,
      name: "Promissores",
      opacity: 0.01,
    },
  ];
  axisNumbers = [0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100];

  /**
   * @description Subscription to hide and disable active axis
   */
  initCoordEventOut() {
    this.subscriptions.push(
      this.outEventObserver.pipe(debounceTime(300)).subscribe((series) => {
        if (
          !this.cursorOnLabel &&
          this.activeCoord &&
          ((series && series.x === this.activeCoord.x && series.y === this.activeCoord.y) || series === null)
        ) {
          this.activeCoord = null;
        }
      })
    );
  }

  setCursorOnLabel(state: boolean) {
    this.cursorOnLabel = state;
    if (!state) {
      this.activeCoord = null;
    }
  }

  hasOverlap(slice1: SliceArea, slice2: SliceArea): boolean {
    /**
     * @see https://www.geeksforgeeks.org/find-two-rectangles-overlap/
     */

    // If one rectangle is on left side of other
    if (slice1.x >= slice2.width + slice2.x || slice2.x >= slice1.width + slice1.x) return false;

    // If one rectangle is above other
    if (slice1.y >= slice2.height + slice2.y || slice2.y >= slice1.height + slice1.y) return false;

    return true;
  }

  areaLabelPositions() {
    for (let index = 0; index < this.areas.length; index++) {
      const currentArea = this.areas[index];
      const overlappingAreas = this.areas.filter((area, i) => {
        if (i <= index) return false;
        return this.hasOverlap(currentArea, area);
      });
      let areaSlices: SliceArea[] = [
        {
          x: currentArea.x,
          y: currentArea.y,
          width: currentArea.width,
          height: currentArea.height,
        },
      ];
      for (const overlappingArea of overlappingAreas) {
        const newSlices: SliceArea[] = [];

        for (const slice of areaSlices) {
          if (this.hasOverlap(slice, overlappingArea)) {
            const horizontalCoords = this.splitSliceHorizontally(slice, overlappingArea);

            const verticalCoords = this.splitSliceVertically(slice, overlappingArea);
            const slices = this.createSlices(horizontalCoords, verticalCoords);
            newSlices.push(...slices);
          }
        }
        areaSlices.push(...newSlices);

        areaSlices = this.joinSliceSideBySide(areaSlices.filter((slice) => !this.hasOverlap(overlappingArea, slice)));
      }
      const orderedSlices = areaSlices.sort((a, b) => b.height - a.height).sort((a, b) => b.width - a.width);
      const position = orderedSlices[0];

      this.plotAreas.push({
        ...this.areas[index],
        labelX: position.x + position.width / 2,
        labelY: position.y + position.height / 2,
      });
    }
  }

  /** svg viewbox */
  viewBox = "0 0 0 0";

  private splitSliceHorizontally(slice: SliceArea, overlappingArea: Area) {
    return this.splitSlice(
      { start: slice.x, size: slice.width },
      { start: overlappingArea.x, size: overlappingArea.width }
    ).map((slice) => ({ x: slice.start, width: slice.size }));
  }

  private splitSliceVertically(slice: SliceArea, overlappingArea: Area) {
    return this.splitSlice(
      { start: slice.y, size: slice.height },
      { start: overlappingArea.y, size: overlappingArea.height }
    ).map((slice) => ({ y: slice.start, height: slice.size }));
  }

  private createSlices(horizontalCoords: SliceHorizontalCoords[], verticalCoords: SliceVericalCoords[]) {
    const slices: SliceArea[] = [];
    for (const horizontalCord of horizontalCoords) {
      for (const verticalCord of verticalCoords) {
        slices.push({
          x: horizontalCord.x,
          y: verticalCord.y,
          width: horizontalCord.width,
          height: verticalCord.height,
        });
      }
    }
    return slices;
  }
  private joinSliceSideBySide(slices: SliceArea[]) {
    const deletedSliceIndexs: number[] = [];
    for (let sliceIndex = 0; sliceIndex < slices.length; sliceIndex++) {
      if (deletedSliceIndexs.includes(sliceIndex)) continue;
      const slice = slices[sliceIndex];
      for (let matchIndex = 0; matchIndex < slices.length; matchIndex++) {
        if (matchIndex === sliceIndex || deletedSliceIndexs.includes(matchIndex)) continue;
        const matchSlice = slices[matchIndex];
        if (slice.height === matchSlice.height && slice.y === matchSlice.y) {
          slice.width = slice.width + matchSlice.width;
          if (matchSlice.x + matchSlice.width === slice.x) {
            slice.x = matchSlice.x;
          }
          deletedSliceIndexs.push(matchIndex);
        }
      }
    }
    return slices.filter((_, index) => !deletedSliceIndexs.includes(index));
  }

  private splitSlice(sliceA: OneDimensionPos, sliceB: OneDimensionPos) {
    if (
      (sliceA.start <= sliceB.start && sliceA.start + sliceA.size > sliceB.start) ||
      (sliceB.start <= sliceA.start && sliceB.start + sliceB.size > sliceA.start)
    ) {
      const points: number[] = [];
      points.push(sliceA.start);
      points.push(sliceA.start + sliceA.size);
      points.push(sliceB.start);
      points.push(sliceB.start + sliceB.size);
      const uniquePoints = points
        .filter((point, index, points) => points.indexOf(point) === index)
        .sort((a, b) => a - b);
      const [result] = uniquePoints.reduce(
        ([result, oldCoord]: [OneDimensionPos[], number | null], coord) => {
          if (oldCoord != null)
            result.push({
              start: oldCoord,
              size: coord - <number>(<unknown>oldCoord),
            });
          return [result, coord];
        },
        [[], null]
      );
      return result;
    } else {
      return [sliceA, sliceB];
    }
  }

  /** SVG current width */
  width = 0;

  /** SVG current height */
  height = 0;

  chartAreaWidth = 160;
  chartAreaHeight = 90;

  /** SVG smallest x */
  x = 0;

  /** SVG smallest y */
  y = 0;

  finishedLoading = false;

  /**
   * @property Property for template series
   */
  chartCircleSeries: PlotSeries[] = [];

  chartLineSeries: PlotSeries[] = [];

  /**
   * @property Property for template labels
   */
  chartLabels: PositionWithTextIndex[] = [];

  /**
   *  @property Property for template floating label
   */
  floatingLabel: FloatingLabel | null = null;

  /**
   *  @property Property to save which series the user clicked
   */
  activeCoord: Position | null = null;

  /**
   * @property Property for template, empty path is path with all dots in center, it serves for the transitions
   */
  emptyPath: string;

  ngOnInit() {
    this.initCoordEventOut();
    this.areaLabelPositions();
    this.initSeries();
    this.initSize();
  }

  /**
   * @description Updates the subscription in data if it changes
   * @param changes
   */
  ngOnChanges(changes: SimpleChanges) {
    if (changes.data) {
      if (this.dataSubscription) {
        this.dataSubscription.unsubscribe();
      }
      const data = changes.data.currentValue as Observable<PracticeVsPerformanceChart>;
      this.dataSubscription = data.subscribe((chart) => {
        this.maxLevel.next(chart.maxLevel);
        this.series.next(chart.series);
        this.axisLabels.next(chart.axisLabels);
      });
    }
  }

  /**
   * @description Close all subscriptions and behaviorSubject
   */
  ngOnDestroy() {
    if (this.dataSubscription) {
      this.dataSubscription.unsubscribe();
    }
    this.maxLevel.complete();
    this.axisLabels.complete();
    this.series.complete();
    this.moveEventObserver.complete();
    this.moveLabelEventObserver.complete();
    this.outLabelEventObserver.complete();
    this.editableEventObserver.complete();
    this.outEventObserver.complete();

    for (const subscriptions of this.subscriptions) {
      subscriptions.unsubscribe();
    }
  }

  /**
   * @description Update the SVG size
   */
  initSize() {
    const updateSize = () => {
      const rect = this.groupChart.nativeElement?.getBBox() as SVGRect;
      this.x = Math.min(this.x, rect.x);
      this.y = Math.min(this.y, rect.y);
      this.width = Math.max(this.width, rect.width);
      this.height = Math.max(this.height, rect.height);
      this.viewBox = `${this.x} ${this.y} ${this.width} ${this.height}`;
      this.finishedLoading = true;
    };

    setTimeout(() => {
      this.subscriptions.push(
        combineLatest([this.maxLevel, this.series])
          .pipe(deepDistinct())
          .subscribe(() => {
            setTimeout(() => {
              updateSize();
            });
          })
      );
    });
  }

  /**
   *  @description Subscription for update the series if series or maxLevel changes
   */
  initSeries() {
    this.subscriptions.push(
      combineLatest([this.series.pipe(deepDistinct()), this.maxLevel.pipe(deepDistinct())]).subscribe(
        ([allSeries, maxLevel]) => {
          const samePointCountMax = new Map<string, number>();
          const newCircleSeries: PlotSeries[] = [];
          const newLineSeries: PlotSeries[] = [];
          for (const series of allSeries) {
            if (series.values.length < 2) continue;
            const key = series.values[0] + "_" + series.values[1];
            const count = (samePointCountMax.get(key) || 0) + 1;
            samePointCountMax.set(key, count);
          }
          const samePointCount = new Map<string, number>();

          for (const series of allSeries) {
            if (series.values.length < 2) continue;
            const key = series.values[0] + "_" + series.values[1];
            const count = (samePointCount.get(key) || 0) + 1;
            const max = samePointCountMax.get(key) as number;
            const currentSeries = {
              id: series.id,
              name: series.name,
              x: (series.values[0] / maxLevel) * 100,
              y: (series.values[1] / maxLevel) * 100,
              path: series.lineFormat
                ? this.renderPathLineSerie(series, maxLevel, count - 1, max)
                : this.renderPathCicleSerie(series, maxLevel, count - 1, max),
              color: series.color,
            };
            if (series.lineFormat) {
              newLineSeries.push(currentSeries);
            } else {
              newCircleSeries.push(currentSeries);
            }
            samePointCount.set(key, count);
          }
          this.chartCircleSeries = newCircleSeries;
          this.chartLineSeries = newLineSeries;
        }
      )
    );
  }

  /**
   * @description Set active axis and series
   * @param axis
   * @param seriesId
   */
  coordEventSetActive(coord: Position) {
    this.activeCoord = coord;
    const rect = this.svgElement.nativeElement?.getBoundingClientRect() as DOMRect;
    if (rect) {
      const series: PlotSeries[] = this.chartCircleSeries.filter(
        (series) => series.x === coord.x && series.y === coord.y
      );
      this.floatingLabel = {
        series,
        x: (((coord.x / 100) * this.chartAreaWidth - this.x) / this.width) * rect.width,
        y: (((1 - coord.y / 100) * this.chartAreaHeight - this.y) / this.height) * rect.height,
      };
    }
  }

  roundNumber(number: number) {
    return number.toFixed(1);
  }

  renderPathLineSerie(
    series: PerformanceVsPracticeSeries,
    maxLevel: number,
    slice: number,
    numberOfSlices: number
  ): string {
    const x = (series.values[0] / maxLevel) * this.chartAreaWidth;
    const y = (1 - series.values[1] / maxLevel) * this.chartAreaHeight;
    const halfStrokeSize = 0.4;
    const stokeDashSize = 4;
    const stokeDashSpace = 5;
    const strokeSize = stokeDashSize + stokeDashSpace;
    const startDelta = stokeDashSize / 2;
    const slices: string[] = [];
    const amountOfHorizontalStoke = Math.ceil(this.chartAreaWidth / strokeSize);
    for (let i = 0; i < amountOfHorizontalStoke; i++) {
      slices.push(
        this.renderRetangle({
          x: startDelta + i * strokeSize,
          y: y - halfStrokeSize,
          width: stokeDashSize,
          height: halfStrokeSize * 2,
        })
      );
    }

    const amountOfVerticalStoke = Math.ceil(this.chartAreaHeight / strokeSize);
    for (let i = 0; i < amountOfVerticalStoke; i++) {
      slices.push(
        this.renderRetangle({
          x: x - halfStrokeSize,
          y: startDelta + i * strokeSize,
          width: halfStrokeSize * 2,
          height: stokeDashSize,
        })
      );
    }
    return slices.join(" ");
  }

  renderRetangle(retangle: SliceArea): string {
    return `M ${retangle.x}, ${retangle.y} ${retangle.x + retangle.width}, ${retangle.y} ${
      retangle.x + retangle.width
    }, ${retangle.y + retangle.height} ${retangle.x}, ${retangle.y + retangle.height}`;
  }

  renderPathCicleSerie(
    series: PerformanceVsPracticeSeries,
    maxLevel: number,
    slice: number,
    numberOfSlices: number
  ): string {
    const x = (series.values[0] / maxLevel) * this.chartAreaWidth;
    const y = (1 - series.values[1] / maxLevel) * this.chartAreaHeight;
    if (numberOfSlices == 1) {
      const radius = 1;
      return `M ${x - radius}, ${y}
    a ${radius},${radius} 0 1,0 ${radius * 2},0
    a ${radius},${radius} 0 1,0 ${-(radius * 2)},0`;
    }
    const radius = 1.5;
    const angle = (2 * Math.PI) / numberOfSlices;

    const angleStart = angle * slice;
    const angleEnd = angle * (slice + 1);
    const startX = radius * Math.cos(angleStart);
    const startY = radius * -Math.sin(angleStart);
    const endX = radius * Math.cos(angleEnd);
    const endY = radius * -Math.sin(angleEnd);
    return `M ${x.toFixed(5)},${y.toFixed(5)} l ${startX},${startY} a ${radius} ${radius} 0 0 ${
      angleStart > angleEnd ? 1 : 0
    } ${endX - startX},${endY - startY}z`;
  }

  trackByAxis(index: number, plotDot: PlotDot) {
    return plotDot.axis;
  }

  trackById(index: number, element: ElementWithId) {
    return element.id;
  }
}
