import { Observable, BehaviorSubject, Subscription, combineLatest } from "rxjs";
import { deepDistinct } from "../libs/operators/deep-distinct";
import { Component, Input, SimpleChanges } from "@angular/core";

interface Position {
  x: number;
  y: number;
}

interface ElementWithId {
  id: number;
}

interface PlotBar extends Position {
  value: number;
  axis: number;
}

interface PlotAxis {
  label: string;
  series: BarPlotSeries[];
}

interface BarPlotSeries extends ElementWithId {
  id: number;
  value: number;
  color: string;
}

export interface BarsSeries {
  id: number;
  name: string;
  values: number[];
  color: string;
}

export interface BarsLabel {
  code: string;
}

export interface BarsChart {
  numberOfAxis: number;
  axisLabels: BarsLabel[];
  series: BarsSeries[];
}

@Component({
  selector: "bm-bars",
  templateUrl: "./bars.component.html",
  styleUrls: ["./bars.component.sass"],
})
export class BarsComponent {
  constructor() {}

  @Input() data: Observable<BarsChart>;

  dataSubscription: Subscription | null;
  subscriptions: Subscription[] = [];

  backgroundBaseSize: number = 0;
  backgroundLevelSpace: number = 30;

  numberOfAxis = new BehaviorSubject<number>(0);
  axisCodeLabels = new BehaviorSubject<string[]>([]);
  series = new BehaviorSubject<BarsSeries[]>([]);

  axisNumbers = [0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100];

  /** SVG smallest x */
  x = 0;

  /** SVG smallest y */
  y = 0;

  finishedLoading = false;

  /**
   * @property Property for template axis
   */
  plotAxis: PlotAxis[] = [];

  /**
   * @property Property for template labels
   */
  chartLabels: string[] = [];

  ngOnInit() {
    this.initSeries();
  }

  /**
   * @description Updates the subscription in data if it changes
   * @param changes
   */
  ngOnChanges(changes: SimpleChanges) {
    if (changes.data) {
      if (this.dataSubscription) {
        this.dataSubscription.unsubscribe();
      }
      const data = changes.data.currentValue as Observable<BarsChart>;
      this.dataSubscription = data.subscribe((chart) => {
        this.numberOfAxis.next(chart.numberOfAxis);
        this.series.next(chart.series);
        this.axisCodeLabels.next(chart.axisLabels.map((axis) => axis.code));
      });
    }
  }

  /**
   * @description Close all subscriptions and behaviorSubject
   */
  ngOnDestroy() {
    if (this.dataSubscription) {
      this.dataSubscription.unsubscribe();
    }

    this.numberOfAxis.complete();
    this.axisCodeLabels.complete();
    this.series.complete();

    for (const subscriptions of this.subscriptions) {
      subscriptions.unsubscribe();
    }
  }

  /**
   *  @description Subscription for update the series if series numberOfAxis changes
   */
  initSeries() {
    this.subscriptions.push(
      combineLatest([
        this.series.pipe(deepDistinct()),
        this.numberOfAxis.pipe(deepDistinct()),
        this.axisCodeLabels.pipe(deepDistinct()),
      ]).subscribe(([allSeries, numberOfAxis, axisCodeLabels]) => {
        const plotAxis: PlotAxis[] = [];
        for (let index = 0; index < numberOfAxis; index++) {
          plotAxis.push({
            label: axisCodeLabels[index],
            series: allSeries.map((series) => ({
              id: series.id,
              value: series.values[index],
              color: series.color,
            })),
          });
        }
        this.plotAxis = plotAxis;
      })
    );
  }

  trackByAxis(index: number, plotDot: PlotBar) {
    return plotDot.axis;
  }

  trackById(index: number, element: ElementWithId) {
    return element.id;
  }
}
