<div class="bm-radar" [ngStyle]="{ opacity: finishedLoading ? 1 : 0 }">
  <svg
    #svg
    class="bm-radar-svg"
    [attr.viewBox]="viewBox"
    xmlns="http://www.w3.org/2000/svg"
    (touchmove)="moveEventObserver.next($event)"
    (mousemove)="moveEventObserver.next($event)"
    (mouseleave)="outEventObserver.next()"
  >
    <filter id="bm-radar-blur">
      <feGaussianBlur in="SourceGraphic" stdDeviation="5"></feGaussianBlur>
    </filter>
    <filter id="bm-radar-blur-text">
      <feGaussianBlur in="SourceGraphic" stdDeviation="1"></feGaussianBlur>
    </filter>
    <g #groupChart>
      <path
        *ngFor="let d of chartBackground; trackBy: trackById"
        fill="#1367a5"
        fill-opacity="0"
        stroke="#bbb"
        stroke-width="4"
        [attr.d]="d.value"
      ></path>
      <path
        *ngFor="let d of chartAxisLine"
        [attr.d]="d"
        stroke="#bbb"
        stroke-width="4"
      ></path>
      <ng-container *ngFor="let series of chartSeries; trackBy: trackById">
        <path
          #path
          [ngClass]="{ 'bm-radar-path-editable': series.editable }"
          stroke-width="2"
          [attr.fill-opacity]="series.noBackground ? 0 : 0.15"
          [attr.fill]="series.color"
          [attr.stroke]="series.color"
        ></path>
        <path
          #pathBlur
          [ngClass]="{ 'bm-radar-path-editable': series.editable }"
          stroke-width="2"
          fill-opacity="0.15"
          [attr.fill]="series.color"
          [attr.stroke]="series.color"
          stroke-opacity="0.05"
          [attr.opacity]="series.noBackground ? 0 : 1"
          [attr.filter]="series.noBackground ? '' : 'url(#bm-radar-blur)'"
        ></path>
      </ng-container>
      <text
        *ngFor="let label of levelLabels"
        [attr.x]="label.x"
        [attr.y]="label.y"
        dominant-baseline="central"
        text-anchor="middle"
        style="font-weight: bold"
        fill="#333"
        font-size="20"
        paint-order="stroke"
        stroke-width="3"
        stroke="white"
        stroke-opacity="0.5"
        [textContent]="label.text"
      />
      <ng-container *ngFor="let series of chartSeries; trackBy: trackById">
        <g
          class="bm-radar-dot-group bm-radar-dot-transition"
          *ngFor="let dot of series.dots; trackBy: trackByAxis"
          [ngClass]="{ 'bm-radar-dot-group-editable': dot.axis == activeAxis }"
          [ngStyle]="{
            transform: 'translate(' + dot.x + 'px, ' + dot.y + 'px)'
          }"
          (mouseup)="outEventObserver.next()"
          (touchend)="outEventObserver.next()"
          (touchstart)="dotEventDown(dot.axis, series.id)"
          (mousedown)="dotEventDown(dot.axis, series.id)"
        >
          <circle
            cx="0"
            cy="0"
            r="4"
            [attr.fill]="series.color"
            [attr.stroke]="series.color"
          ></circle>
          <text
            x="0"
            y="0"
            dominant-baseline="central"
            text-anchor="middle"
            fill="white"
            [textContent]="roundNumber(dot.value)"
          />
        </g>
      </ng-container>

      <g
        #axisCode
        *ngFor="let label of chartLabels; let i = index"
        [attr.opacity]="label.opacity"
        (mousemove)="moveLabelEventObserver.next(label)"
        (mouseout)="outLabelEventObserver.next(null)"
      >
        <text
          *ngFor="let line of label.text; let i = index"
          class="bm-radar-label"
          [attr.x]="label.x"
          [attr.y]="label.y + lineHeight * i"
          dominant-baseline="middle"
          text-anchor="middle"
          fill="black"
          font-size="16"
          fill-opacity="0.9"
          [attr.transform-origin]="label.x + ' ' + label.y"
          [textContent]="label.text[i]"
        />
      </g>
    </g>
  </svg>
  <div
    *ngIf="floatingLabel"
    class="bm-radar-hover-label-wrapper"
    [ngStyle]="{
      'left.px': floatingLabel.x - 256,
      'top.px': floatingLabel.y - 64
    }"
  >
    <div
      class="bm-radar-hover-label"
      [ngClass]="{ 'bm-radar-hover-label-show': floatingLabelShow }"
      [textContent]="floatingLabel.text"
    ></div>
  </div>
  <bm-legend [series]="series"></bm-legend>
</div>
