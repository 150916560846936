import {
  ElementRef,
  Component,
  Output,
  Input,
  EventEmitter,
} from "@angular/core";

import {
  SurveyIndicator,
  ElementWithId,
} from "../questions-component/interfaces";

export const selector = "bm-indicator";

@Component({
  templateUrl: "./indicator.component.html",
  styleUrls: ["./indicator.component.sass"],
  selector,
})
export class IndicatorComponent {
  @Input() indicator: SurveyIndicator;
  @Input() error: boolean;
  @Input() index: number;
  @Output() value = new EventEmitter<number | null>();

  constructor(protected elementRef: ElementRef<HTMLElement>) {}

  ngOnInit() {
    const dataSet = this.elementRef.nativeElement.dataset;
    if (dataSet.indicator) {
      this.indicator = JSON.parse(dataSet.indicator);
    }
    if (dataSet.index) {
      this.index = parseInt(dataSet.index);
    }
  }

  onNoteClick(noteId: number) {
    if (this.indicator.noteSelected === noteId) this.value.emit(null);
    else this.value.emit(noteId);
  }

  trackById(index: number, item: ElementWithId) {
    return item.id;
  }
}
