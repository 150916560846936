import { Injectable } from "@angular/core";
import { HttpErrorResponse, HttpClient } from "@angular/common/http";
import { GonService } from "core-app/modules/common/gon/gon.service";

import { Observable, throwError } from "rxjs";
import { catchError, retry } from "rxjs/operators";
import { AssessedData, GroupData, SaveAssessedData } from "./consolidate-dashboard.component";
import { SaveResponse } from "../questions-component/interfaces";

@Injectable()
export class ConsolidateDashboardService {
  constructor(private http: HttpClient, private gon: GonService) {}

  getGroupData(groupId: string): Observable<GroupData> {
    const urlString = this.gon.get("group_data_url") as string;

    const url = new URL(urlString, window.location.href);

    url.searchParams.set("group_id", groupId);

    return this.http.get<GroupData>(url.href).pipe(retry(3), catchError(this.handleError));
  }

  getAssessedData(groupId: string, assessedId: string): Observable<AssessedData> {
    const urlString = this.gon.get("assessed_data_url") as string;

    const url = new URL(urlString, window.location.href);

    url.searchParams.set("group_id", groupId);
    url.searchParams.set("assessed_id", assessedId);

    return this.http.get<AssessedData>(url.href).pipe(retry(3), catchError(this.handleError));
  }

  saveAssessedData(assessedData: SaveAssessedData): Observable<SaveResponse> {
    const urlString = this.gon.get("assessed_data_url") as string;
    const url = new URL(urlString, window.location.href);

    return this.http
      .post<SaveResponse>(url.href, assessedData, { withCredentials: true })
      .pipe(catchError(this.handleError));
  }

  private handleError(error: HttpErrorResponse) {
    try {
      if (error.error instanceof ErrorEvent) {
        // A client-side or network error occurred. Handle it accordingly.
        console.error("An error occurred:", error.error.message);
        return throwError("Something bad happened; please try again later.");
      } else {
        console.error(`Backend returned code ${error.status}, body` + error.error);
        return throwError(error.error.message);
      }
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong.
    } catch (err) {
      return throwError("Something bad happened; please try again later.");
    }
  }
}
