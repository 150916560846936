import { Injectable, Pipe, PipeTransform } from "@angular/core";

export type SortOrder = "asc" | "desc";

interface WithPosition {
  position: string | number;
}

@Injectable()
@Pipe({
  name: "orderByPosition",
})
export class OrderByPositionPipe implements PipeTransform {
  transform<T extends WithPosition>(values: T[]): T[] {
    return values.sort((a, b) => {
      if (a.position === b.position) return 0;
      return [a.position, b.position].sort()[0] === a.position ? 1 : -1;
    });
  }
}
