<div class="bm-practice-vs-performance" [ngStyle]="{ opacity: finishedLoading ? 1 : 0 }">
  <svg #svg class="bm-practice-vs-performance-svg" [attr.viewBox]="viewBox" xmlns="http://www.w3.org/2000/svg">
    <mask x="0" y="0" [attr.width]="chartAreaWidth" [attr.height]="chartAreaHeight" id="bmMask">
      <rect x="0" y="0" [attr.width]="chartAreaWidth" [attr.height]="chartAreaHeight" fill="#fff"></rect>
      <text
        *ngFor="let area of plotAreas"
        [attr.x]="(chartAreaWidth * area.labelX) / 100"
        [attr.y]="(chartAreaHeight * (100 - area.labelY)) / 100"
        font-weight="bold"
        dominant-baseline="central"
        text-anchor="middle"
        stroke="#000"
        paint-order="stroke"
        stroke-width="2"
        fill="#000"
        font-size="3"
        [textContent]="area.name"
      />
    </mask>
    <g #groupChart>
      <rect
        *ngFor="let area of plotAreas"
        fill="#2b2b2b"
        [attr.fill-opacity]="area.opacity"
        [attr.x]="(chartAreaWidth * area.x) / 100"
        [attr.y]="(chartAreaHeight * (100 - area.y - area.height)) / 100"
        [attr.width]="(chartAreaWidth * area.width) / 100"
        [attr.height]="(chartAreaHeight * area.height) / 100"
      />

      <ng-container *ngFor="let series of chartLineSeries; trackBy: trackById">
        <path [attr.d]="series.path" [attr.fill]="series.color" mask="url(#bmMask)"></path>
        <text
          dominant-baseline="central"
          text-anchor="middle"
          font-size="3"
          font-weight="bold"
          [attr.fill]="series.color"
          [attr.x]="(chartAreaWidth * series.x) / 100"
          y="-2"
          [textContent]="series.name"
        />
        <text
          dominant-baseline="central"
          font-size="3"
          font-weight="bold"
          [attr.x]="chartAreaWidth + 1"
          [attr.fill]="series.color"
          [attr.y]="(chartAreaHeight * (100 - series.y)) / 100"
          [textContent]="series.name"
        />
      </ng-container>

      <text
        *ngFor="let area of plotAreas"
        [attr.x]="(chartAreaWidth * area.labelX) / 100"
        [attr.y]="(chartAreaHeight * (100 - area.labelY)) / 100"
        font-weight="bold"
        dominant-baseline="central"
        text-anchor="middle"
        fill="#1A67A3"
        font-size="3"
        [textContent]="area.name"
      />
      <text
        *ngFor="let number of axisNumbers"
        x="-3"
        [attr.y]="(chartAreaHeight * (100 - number)) / 100"
        font-weight="bold"
        dominant-baseline="central"
        text-anchor="end"
        fill="#1A67A3"
        font-size="3"
        [textContent]="number + '%'"
      />
      <text
        *ngFor="let number of axisNumbers"
        [attr.x]="(chartAreaWidth * number) / 100"
        [attr.y]="chartAreaHeight + 3"
        font-weight="bold"
        dominant-baseline="central"
        text-anchor="middle"
        fill="#1A67A3"
        font-size="3"
        [textContent]="number + '%'"
      />

      <text
        [attr.x]="chartAreaWidth / 2"
        [attr.y]="chartAreaHeight + 7"
        font-weight="bold"
        dominant-baseline="central"
        text-anchor="middle"
        fill="#1A67A3"
        font-size="4"
        [textContent]="(axisLabels | async)[0]"
      />

      <text
        [attr.transform-origin]="'-13 ' + chartAreaHeight / 2"
        transform="rotate(-90)"
        x="-13"
        [attr.y]="chartAreaHeight / 2"
        font-weight="bold"
        dominant-baseline="central"
        text-anchor="middle"
        fill="#1A67A3"
        font-size="4"
        [textContent]="(axisLabels | async)[1]"
      />
      <path
        *ngFor="let series of chartCircleSeries; trackBy: trackById"
        [ngClass]="{
          'bm-practice-vs-performance': series.id == activeSeriesId
        }"
        [attr.fill]="series.color"
        [attr.d]="series.path"
      />
      <circle
        *ngFor="let series of chartCircleSeries; trackBy: trackById"
        (touchstart)="coordEventSetActive(series)"
        (mouseleave)="outEventObserver.next(series)"
        (touchend)="outEventObserver.next(series)"
        (mouseover)="coordEventSetActive(series)"
        [attr.cx]="(chartAreaWidth * series.x) / 100"
        [attr.cy]="(chartAreaHeight * (100 - series.y)) / 100"
        r="3"
        fill="transparent"
      />
      <rect
        class="bm-practice-vs-performance-no-mouse-interaction"
        stroke-width="0.2"
        fill="transparent"
        stroke="#d9d9d9"
        [attr.width]="chartAreaWidth"
        [attr.height]="chartAreaHeight"
      ></rect>
    </g>
  </svg>
  <div
    *ngIf="activeCoord"
    class="bm-practice-vs-performance-hover-label-wrapper"
    [ngStyle]="{
      'left.px': floatingLabel.x,
      'top.px': floatingLabel.y - 25
    }"
  >
    <div
      (mouseleave)="setCursorOnLabel(false)"
      (mouseover)="setCursorOnLabel(true)"
      class="bm-practice-vs-performance-hover-label"
      [ngClass]="{ 'bm-practice-vs-performance-hover-label-show': activeCoord }"
    >
      <div *ngFor="let series of floatingLabel.series; let i = index; trackBy: trackById">
        <div class="bm-practice-vs-performance-hover-label-series">
          <svg width="12px" height="8px" xmlns="http://www.w3.org/2000/svg">
            <circle cx="4" cy="4" r="4" [attr.fill]="series.color"></circle>
          </svg>
          <span>{{ series.name }}</span>
        </div>
        <div *ngIf="i == 0">
          <div class="bm-practice-vs-performance-hover-label-axis">
            <span class="bm-practice-vs-performance-hover-label-axis-title">{{ (axisLabels | async)[0] }}: </span>
            <span> {{ roundNumber(series.x) }}%</span>
          </div>
          <div class="bm-practice-vs-performance-hover-label-axis">
            <span class="bm-practice-vs-performance-hover-label-axis-title">{{ (axisLabels | async)[1] }}: </span>
            <span> {{ roundNumber(series.y) }}%</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
