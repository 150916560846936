<div class="bm-questions">
  <h2>{{ survey.group.description }}</h2>
  <div class="bm-assessing-sticky">
    <h3 class="bm-assessing-msg">{{ text.assessingMSG }}</h3>
    <span class="bm-assessing-error" [textContent]="error"></span>
  </div>
  <div class="bm-indicators-wrapper">
    <bm-indicator
      *ngFor="
        let indicator of indicators;
        trackBy: trackById;
        let index = index
      "
      class="bm-indicator"
      [indicator]="indicator"
      [index]="index + 1"
      (value)="indicator.noteSelected = $event"
      [error]="showBlankAssessmentAlert && indicator.noteSelected == null"
    ></bm-indicator>
    <button
      (click)="saveAnswers()"
      name="button"
      type="submit"
      class="-highlight -with-icon icon-checkmark button"
    >
      {{ text.save }}
    </button>
  </div>
</div>
