<div *ngIf="errorMsg; else elseBlock" class="bm-consolidate-error">
  <span class="bm-consolidate-error-msg">{{ errorMsg }}</span>
</div>
<ng-template #elseBlock>
  <div class="bm-consolidate">
    <ng-container
      *ngFor="let card of cards | orderByPosition; trackBy: trackCard"
    >
      <div
        class="bm-consolidate-assessed"
        [ngClass]="{
          'bm-consolidate-assessed-completed': card.completed,
          'bm-consolidate-assessed-large': card.isLarge,
          'bm-consolidate-assessed-closed': card.isClosed
        }"
      >
        <div class="bm-consolidate-assessed-header">
          <div>
            <span class="bm-consolidate-label">{{ card.group.name }}</span>
            ·
            {{ card.name }}
          </div>
          <div class="bm-consolidate-label">
            {{ card.completed ? text.consolidatedChart : "" }}
          </div>
        </div>
        <div class="bm-consolidate-radars">
          <div
            @charts
            *ngIf="card.assessedChart"
            (@charts.done)="animCardSizeEnd(card)"
            class="bm-consolidate-radar-wrapper"
          >
            <span class="bm-consolidate-radar-label">{{
              this.text.individualChart
            }}</span>
            <div class="bm-consolidate-radar">
              <bm-radar [data]="card.assessedChart"></bm-radar>
            </div>
          </div>
          <div class="bm-consolidate-radar-wrapper">
            <span class="bm-consolidate-radar-label">{{
              card.completed
                ? this.text.consolidatedChart
                : this.text.consolidateChart
            }}</span>
            <div class="bm-consolidate-radar">
              <bm-radar
                (updated)="onFinshedLoadingChart(card)"
                (editableChange)="editable.next({ card: card, event: $event })"
                [data]="card.avgChart"
              ></bm-radar>
            </div>
          </div>
        </div>
        <div class="bm-consolidate-assessed-footer">
          <div class="bm-consolidate-error-msg">
            {{ card.errorMsg }}
          </div>
          <button
            *ngIf="!card.completed"
            (click)="consolidate(card, $event)"
            class="button bm-consolidate-assessed-footer-button -alt-highlight"
            [ngClass]="{
              'bm-consolidate-assessed-footer-button-loading-active': !card.buttonStatus
            }"
          >
            <div class="bm-consolidate-assessed-footer-button-loading"></div>
            {{ text.button }}
          </button>
        </div>
      </div>
    </ng-container>
  </div>
</ng-template>
