import { Component, OnInit, Injector } from "@angular/core";

import { InjectField } from "core-app/helpers/angular/inject-field.decorator";
import { I18nService } from "core-app/modules/common/i18n/i18n.service";
import { GonService } from "core-app/modules/common/gon/gon.service";
import { GroupData } from "../assessments-dashboard-component/assessments-dashboard.component";

export const selector = "bm-assessments-dashboard-filter";

export interface FilterItem {
  name: string;
  status: boolean;
}
type CallbackArgsDashboardFilter =
  | {
      type: "updatedItem";
      item: FilterItem;
      list: FilterItem[];
    }
  | { type: "newList"; list: FilterItem[] };

interface WindowBrEduIfscBenchmarking {
  dashboardFilter: {
    setList(list: string[]): void;
    addListener(callback: (event: CallbackArgsDashboardFilter) => void): void;
    close(): void;
  };
}

interface WindowBrEduIfsc {
  benchmarking: WindowBrEduIfscBenchmarking;
}

interface WindowBrEdu {
  ifsc?: WindowBrEduIfsc;
}

interface WindowBr {
  edu?: WindowBrEdu;
}

export interface WindowWithFilter {
  br?: WindowBr;
}

@Component({
  templateUrl: "./assessments-dashboard-filter.component.html",
  styleUrls: ["./assessments-dashboard-filter.component.sass"],
  selector,
})
export class AssessmentsDashboardFilterComponent {
  @InjectField() I18n: I18nService;
  list: FilterItem[];
  descriptionFilter = "";
  constructor(readonly injector: Injector, readonly gon: GonService) {
    const dashboardFilter = (window as WindowWithFilter).br?.edu?.ifsc?.benchmarking?.dashboardFilter;
    if (dashboardFilter) {
      dashboardFilter.addListener((event) => {
        if (event.type === "newList") {
          this.list = event.list;
        }
      });
      let groups = this.gon.get("groups") as GroupData[];
      const assesseds = groups.reduce(
        (set, group) => {
          group.assesseds.forEach((assessed) => set.add(assessed.name));
          return set;
        },
        new Set<string>([this.I18n.t("js.consolidate.reference_series")])
      );
      this.descriptionFilter = this.I18n.t("js.description_filter");
      dashboardFilter.setList(Array.from(assesseds));
    }
  }
  ngOnDestroy() {
    const dashboardFilter = (window as WindowWithFilter).br?.edu?.ifsc?.benchmarking?.dashboardFilter;
    if (dashboardFilter) {
      dashboardFilter.close();
      this.list = [];
    }
  }
  toggle(item: FilterItem): void {
    item.status = !item.status;
  }
  trackByName(index: number, item: FilterItem): string {
    return item.name;
  }
}
