<fieldset class="form--fieldset">
  <legend class="form--fieldset-legend">{{ descriptionFilter }}</legend>
  <div *ngFor="let item of list; let i = index; trackBy: trackByName">
    <input
      (click)="toggle(item)"
      class="bm-dashboard-filter-checkbox"
      [id]="'bm-dashboard-filter-item-' + i"
      type="checkbox"
      [checked]="item.status"
    />
    <label class="bm-dashboard-filter-item-label" [for]="'bm-dashboard-filter-item-' + i">{{ item.name }}</label>
  </div>
</fieldset>
