import { ElementRef, Component } from "@angular/core";

import { SurveyIndicator } from "../questions-component/interfaces";

export const selector = "bm-indicator-wrapper";

@Component({
  templateUrl: "./indicator-wrapper.component.html",
  selector,
})
export class IndicatorWrapperComponent {
  indicator: SurveyIndicator;
  index: number = 1;

  constructor(protected elementRef: ElementRef<HTMLElement>) {}

  ngOnInit() {
    const dataSet = this.elementRef.nativeElement.dataset;
    if (dataSet.indicator) {
      this.indicator = JSON.parse(dataSet.indicator);
    }
    if (dataSet.index) {
      this.index = parseInt(dataSet.index);
    }
  }
}
