<div class="bm-bars">
  <ng-container *ngFor="let axis of plotAxis; let i = index">
    <div class="bm-bars-label">{{ axis.label }}</div>
    <div class="bm-bars-series">
      <div
        class="bm-bars-background"
        *ngFor="let series of axis.series; let j = index"
      >
        <div
          class="bm-bars-bar"
          [ngStyle]="{
            'width.%': series.value * 100,
            'background-color': series.color
          }"
        ></div>
      </div>
    </div>
  </ng-container>
  <div class="bm-bars-axis">
    <div
      *ngFor="let number of axisNumbers; let i = index"
      class="bm-bars-number-wrapper"
    >
      <div class="bm-bars-number">
        <div class="bm-bars-number-dot"></div>
        <div>{{ number }}%</div>
      </div>
    </div>
  </div>
</div>
