import {
  Observable,
  BehaviorSubject,
  Subscription,
  combineLatest,
  Subject,
} from "rxjs";
import { debounceTime } from "rxjs/operators";
import { deepDistinct } from "../libs/operators/deep-distinct";
import {
  Component,
  Input,
  SimpleChanges,
  ViewChildren,
  Output,
  EventEmitter,
  QueryList,
  ElementRef,
  ViewChild,
} from "@angular/core";

interface Position {
  x: number;
  y: number;
}

interface SVGElement extends Element {
  beginElement(): SVGElement;
}

interface Transition {
  index: number;
  before: number;
  after: number;
  delta: number;
}

interface PathTransition {
  index: number;
  path: (string | number)[];
  transitions: Transition[];
}

interface ElementWithId {
  id: number;
}

interface PlotDot extends Position {
  value: number;
  axis: number;
}

interface PositionWithTextIndex extends Position {
  text: string[];
  index: number;
}

interface PositionWithStringIndex extends Position {
  text: string;
  index: number;
}

interface PlotSeries extends ElementWithId {
  id: number;
  path: string;
  oldPath: string;
  dots: PlotDot[];
  editable: boolean;
  noBackground: boolean;
  color: string;
}

interface CreatedSeries {
  series: PlotSeries;
  newDots: PlotDot[];
}

export interface ChartSeries {
  id: number;
  name: string;
  values: number[];
  editable?: boolean;
  noBackground?: boolean;
  color: string;
}

interface keyValue extends ElementWithId {
  value: string;
}

export interface ChartLabel {
  label: string;
  code: string;
}

export interface RadarChart {
  numberOfAxis: number;
  maxLevel: number;
  axisLabels: ChartLabel[];
  series: ChartSeries[];
}

export interface ChartEditableChange {
  series: number;
  axis: number;
  newLevel: number;
}

@Component({
  selector: "bm-radar",
  templateUrl: "./radar.component.html",
  styleUrls: ["./radar.component.sass"],
})
export class RadarComponent {
  constructor() {}

  @ViewChild("svg") svgElement: ElementRef;
  @ViewChild("groupChart") groupChart: ElementRef;

  @ViewChildren("path") pathElements: QueryList<ElementRef>;
  @ViewChildren("pathBlur")
  pathBlurElements: QueryList<ElementRef>;

  @Output() editableChange = new EventEmitter<ChartEditableChange>();
  @Output() updated = new EventEmitter<void>();
  @Input() data: Observable<RadarChart>;

  dataSubscription: Subscription | null;
  subscriptions: Subscription[] = [];

  backgroundBaseSize: number = 0;
  backgroundLevelSpace: number = 30;

  numberOfAxis = new BehaviorSubject<number>(0);
  maxLevel = new BehaviorSubject<number>(0);
  axisCodeLabels = new BehaviorSubject<string[]>([]);
  axisLabels = new BehaviorSubject<string[]>([]);
  series = new BehaviorSubject<ChartSeries[]>([]);
  moveEventObserver = new Subject<MouseEvent | TouchEvent>();
  outEventObserver = new Subject<void>();
  moveLabelEventObserver = new Subject<PositionWithTextIndex>();
  outLabelEventObserver = new Subject<null>();
  editableEventObserver = new Subject<ChartEditableChange>();

  /** svg viewbox */
  viewBox = "0 0 0 0";

  /** Axis code line height */
  lineHeight = 20;

  /** SVG current width */
  width = 0;

  /** SVG current height */
  height = 0;

  /** SVG smallest x */
  x = 0;

  /** SVG smallest y */
  y = 0;

  finishedLoading = false;

  /**
   * @property Property for template background
   */
  chartBackground: keyValue[] = [];

  /**
   * @property Property for template of axis lines
   */
  chartAxisLine: String[] = [];

  /**
   * @property Property for template series
   */
  chartSeries: PlotSeries[] = [];

  /**
   * @property Property for template labels
   */
  chartLabels: PositionWithTextIndex[] = [];

  /**
   *  @property Map to save the animate elements
   */
  animateElements = new Map<number, SVGAnimateElement[]>();

  /**
   *  @property Property for template floating label
   */
  floatingLabel: PositionWithStringIndex;

  levelLabels: PositionWithStringIndex[] = [];
  /**
   *  @property Property to hide and show the floating label
   */
  floatingLabelShow: boolean = false;

  /**
   *  @property Property to save which axis the user clicked
   */
  activeAxis: number | null;

  /**
   *  @property Property to save which series the user clicked
   */
  activeSeriesId: number | null;

  /**
   * @property Property for template, empty path is path with all dots in center, it serves for the transitions
   */
  emptyPath: string;

  ngOnInit() {
    this.initSize();
    this.initFloatingLabel();
    this.initBackground();
    this.initLabels();
    this.initLevelLabels();
    this.initEditable();
    this.initSeries();
    this.initDotEventOut();
  }

  /**
   * @description Updates the subscription in data if it changes
   * @param changes
   */
  ngOnChanges(changes: SimpleChanges) {
    if (changes.data) {
      if (this.dataSubscription) {
        this.dataSubscription.unsubscribe();
      }
      const data = changes.data.currentValue as Observable<RadarChart>;
      this.dataSubscription = data.subscribe((chart) => {
        this.maxLevel.next(chart.maxLevel);
        this.numberOfAxis.next(chart.numberOfAxis);
        this.series.next(chart.series);
        this.axisCodeLabels.next(chart.axisLabels.map((axis) => axis.code));
        this.axisLabels.next(chart.axisLabels.map((axis) => axis.label));
      });
    }
  }

  /**
   * @description Close all subscriptions and behaviorSubject
   */
  ngOnDestroy() {
    if (this.dataSubscription) {
      this.dataSubscription.unsubscribe();
    }
    this.numberOfAxis.complete();
    this.maxLevel.complete();
    this.axisCodeLabels.complete();
    this.axisLabels.complete();
    this.series.complete();
    this.moveEventObserver.complete();
    this.moveLabelEventObserver.complete();
    this.outLabelEventObserver.complete();
    this.editableEventObserver.complete();
    this.outEventObserver.complete();

    for (const subscriptions of this.subscriptions) {
      subscriptions.unsubscribe();
    }
  }

  /**
   * @description Update the SVG size
   */
  initSize() {
    const updateSize = () => {
      const rect = this.groupChart.nativeElement?.getBBox() as SVGRect;
      this.x = rect.x;
      this.y = rect.y;
      this.width = rect.width;
      this.height = rect.height;
      this.viewBox = `${rect.x} ${rect.y} ${rect.width} ${rect.height}`;
      this.finishedLoading = true;
    };

    setTimeout(() => {
      this.subscriptions.push(
        combineLatest([
          this.numberOfAxis,
          this.maxLevel,
          this.axisCodeLabels,
        ])
          .pipe(deepDistinct())
          .subscribe(() => {
            updateSize();
          })
      );
    });
  }

  initLevelLabels() {
    this.subscriptions.push(
      this.maxLevel.subscribe((maxLevel) => {
        let angle = -Math.PI / 2;
        for (let index = 1; index <= maxLevel; index++) {
          const position = this.getCircleDot(
            this.getRadiusOfLevel(index),
            angle
          );

          this.levelLabels.push({
            index,
            x: position.x + 22,
            y: position.y - 5,
            text: index.toString(),
          });
        }
      })
    );
  }

  /**
   * @description Subscriptions for the label's move and out event
   */
  initFloatingLabel() {
    // Over event defines the position of the floating label by the label that triggered the event and displays
    this.subscriptions.push(
      combineLatest([
        this.moveLabelEventObserver.pipe(debounceTime(100)),
        this.axisLabels.pipe(deepDistinct()),
      ]).subscribe(([label, axisLabels]) => {
        const svgElement = this.svgElement.nativeElement as SVGElement;
        const rect = svgElement.getBoundingClientRect();
        this.floatingLabel = {
          x: ((label.x - this.x) / this.width) * rect.width,
          y: ((label.y - this.y) / this.height) * rect.height,
          index: label.index,
          text: axisLabels[label.index],
        };
        this.floatingLabelShow = true;
      })
    );
    // Out event just hides the floating label
    this.subscriptions.push(
      this.outLabelEventObserver.pipe(debounceTime(1000)).subscribe(() => {
        this.floatingLabelShow = false;
      })
    );
  }

  /**
   * @description returns the coordinate of the point that belongs to the tangent of the circle
   * @param radius circle radius
   * @param angle angle of dot
   */
  getCircleDot(radius: number, angle: number): Position {
    return {
      x: Math.cos(angle) * radius,
      y: Math.sin(angle) * radius,
    };
  }

  /**
   * @description Get radius by series value
   * @param level
   */
  getRadiusOfLevel(level: number): number {
    return this.backgroundBaseSize + level * this.backgroundLevelSpace;
  }

  /**
   * @description Set active axis and series
   * @param axis
   * @param seriesId
   */
  dotEventDown(axis: number, seriesId: number) {
    this.activeSeriesId = seriesId;
    this.activeAxis = axis;
  }

  /**
   * @description Subscription to hide and disable active axis
   */
  initDotEventOut() {
    this.subscriptions.push(
      this.outEventObserver.pipe(debounceTime(300)).subscribe(() => {
        this.activeAxis = null;
        this.activeSeriesId = null;
      })
    );
  }

  /**
   * @description Subscription for the dot move event to emit editableChange event
   */
  initEditable() {
    this.subscriptions.push(
      this.moveEventObserver.subscribe((event) => {
        if (this.activeSeriesId == null && this.activeAxis == null) return;
        event.preventDefault();
        const allSeries = this.series.getValue();
        const numberOfAxis = this.numberOfAxis.getValue();
        const numberOfLevel = this.maxLevel.getValue();
        if (event.type === "mousemove") {
          if ((event as MouseEvent).buttons != 1) {
            this.activeSeriesId = null;
            this.activeAxis = null;
          }
        }
        if (this.activeSeriesId === null) return;
        if (this.activeAxis === null) return;
        const series = allSeries.find(
          (series) => series.id === this.activeSeriesId
        );
        if (!series || !series.editable) return;
        const svgElement = this.svgElement.nativeElement as SVGElement;
        const rect = svgElement.getBoundingClientRect();
        let eventPos: Position;
        if (event.type === "mousemove") {
          eventPos = {
            x: (event as MouseEvent).clientX,
            y: (event as MouseEvent).clientY,
          };
        } else {
          const touchEvent = event as TouchEvent;
          eventPos = {
            x: touchEvent.touches[touchEvent.touches.length - 1].clientX,
            y: touchEvent.touches[touchEvent.touches.length - 1].clientY,
          };
        }

        const newLevel = this.getLevelCloser(
          {
            x: ((eventPos.x - rect.left) / rect.width) * this.width + this.x,
            y: ((eventPos.y - rect.top) / rect.height) * this.height + this.y,
          },
          this.activeAxis,
          numberOfAxis,
          numberOfLevel
        );
        if (newLevel !== null)
          this.editableEventObserver.next({
            series: this.activeSeriesId,
            axis: this.activeAxis,
            newLevel,
          });
      })
    );

    this.subscriptions.push(
      this.editableEventObserver.pipe(deepDistinct()).subscribe((event) => {
        this.editableChange.emit(event);
      })
    );
  }

  /**
   * @description Subscription for update the background if numberOfAxis or maxLevel changes
   */
  initBackground() {
    this.subscriptions.push(
      combineLatest([
        this.numberOfAxis.pipe(deepDistinct()),
        this.maxLevel.pipe(deepDistinct()),
      ]).subscribe(([numberOfAxis, maxLevel]) => {
        if (numberOfAxis === 0 || maxLevel === 0) {
          this.chartBackground = [];
          this.chartAxisLine = [];
          return;
        }

        this.emptyPath = this.getBackgroundChartFormat(0, numberOfAxis);
        this.chartAxisLine = [];
        const background: keyValue[] = [];
        for (let i = 0; i <= maxLevel; i++) {
          const size = this.getRadiusOfLevel(i);
          background.push({
            id: i,
            value: this.getBackgroundChartFormat(size, numberOfAxis),
          });
        }
        this.chartBackground = background;
        const lineSize = this.getRadiusOfLevel(maxLevel);
        for (let i = 0; i < numberOfAxis; i++) {
          this.chartAxisLine.push(this.getAxisLine(i, lineSize, numberOfAxis));
        }
      })
    );
  }

  /**
   * @description Subscription for update the labels if numberOfAxis, maxLevel or axisCodeLabels changes
   */
  initLabels() {
    this.subscriptions.push(
      combineLatest([
        this.numberOfAxis.pipe(deepDistinct()),
        this.maxLevel.pipe(deepDistinct()),
        this.axisCodeLabels.pipe(deepDistinct()),
      ]).subscribe(([numberOfAxis, maxLevel, axisCodeLabels]) => {
        this.chartLabels = [];
        let angle = -Math.PI / 2;
        const deltaAngle = (Math.PI * 2) / numberOfAxis;
        const size = this.getRadiusOfLevel(maxLevel + 1.5);
        const min = Math.min(numberOfAxis, axisCodeLabels.length);
        for (let i = 0; i < min; i++) {
          const position = this.getCircleDot(size, angle);
          const lines = axisCodeLabels[i].split("\n");

          this.chartLabels.push({
            x: position.x,
            y:
              position.y +
              (lines.length - 1) * this.lineHeight * Math.sin(angle),
            index: i,
            text: lines,
          });
          angle += deltaAngle;
        }
      })
    );
  }

  /**
   *  @description Subscription for update the series if series, numberOfAxis or maxLevel changes
   */
  initSeries() {
    this.subscriptions.push(
      combineLatest([
        this.series.pipe(deepDistinct()),
        this.numberOfAxis.pipe(deepDistinct()),
        this.maxLevel.pipe(deepDistinct()),
      ]).subscribe(([allSeries, numberOfAxis, maxLevel]) => {
        const sortedSeries: ChartSeries[] = allSeries.sort((a, b) =>
          a.editable && !b.editable ? 1 : b.editable ? -1 : 0
        );

        const usedIds: number[] = [];
        const modifiedSeriesPathIds: number[] = [];
        const createdSeries: CreatedSeries[] = [];
        for (const series of sortedSeries) {
          usedIds.push(series.id);

          const currentChartSeries = this.chartSeries.find(
            (chartSeries) => chartSeries.id === series.id
          );

          const filtedValues = series.values
            .slice(0, numberOfAxis)
            .map((value: number) => Math.min(value, maxLevel));

          if (currentChartSeries) {
            const dots = this.getDotPositions(filtedValues, numberOfAxis);

            currentChartSeries.dots.splice(
              numberOfAxis,
              currentChartSeries.dots.length
            );
            for (let index = 0; index < dots.length; index++) {
              if (currentChartSeries.dots[index] != null) {
                currentChartSeries.dots[index].x = dots[index].x;
                currentChartSeries.dots[index].y = dots[index].y;
                currentChartSeries.dots[index].axis = index;
                currentChartSeries.dots[index].value = series.values[index];
              } else {
                // Now, the central position is used because we want the
                // point to leave the center, then we will create create the transition
                const dot: PlotDot = {
                  x: 0,
                  y: 0,
                  axis: index,
                  value: series.values[index],
                };
                currentChartSeries.dots.push(dot);
                // Apply the transition after the element is placed in the DOM
                setTimeout(() => {
                  currentChartSeries.dots[index].x = dots[index].x;
                  currentChartSeries.dots[index].y = dots[index].y;
                }, 0);
              }
            }

            const newPath = this.getPathFormat(dots);

            if (currentChartSeries.path !== newPath) {
              modifiedSeriesPathIds.push(series.id);
            }

            currentChartSeries.color = series.color;
            currentChartSeries.oldPath = currentChartSeries.path;
            currentChartSeries.path = newPath;
            currentChartSeries.editable = series.editable || false;
            currentChartSeries.noBackground = series.noBackground || false;
          } else {
            modifiedSeriesPathIds.push(series.id);
            const dots: PlotDot[] = this.getDotPositions(
              filtedValues,
              numberOfAxis
            ).map((dot, index) => ({
              x: dot.x,
              y: dot.y,
              value: series.values[index],
              axis: index,
            }));

            const initialDots: PlotDot[] = dots.map((dot) => ({
              ...dot,
              x: 0,
              y: 0,
            }));

            const chartSerie: PlotSeries = {
              id: series.id,
              oldPath: this.emptyPath,
              path: this.getPathFormat(dots),
              editable: series.editable || false,
              noBackground: series.noBackground || false,
              dots: initialDots,
              color: series.color,
            };
            this.chartSeries.push(chartSerie);
            createdSeries.push({
              series: chartSerie,
              newDots: dots,
            });
          }
        }

        const deletedPaths: number[] = [];
        for (const series of this.chartSeries) {
          if (!usedIds.includes(series.id)) {
            modifiedSeriesPathIds.push(series.id);
            deletedPaths.push(series.id);
            series.oldPath = series.path;
            series.path = this.emptyPath;
            series.dots = series.dots.map((dot) => {
              dot.x = 0;
              dot.y = 0;
              return dot;
            });
          }
        }

        setTimeout(() => {
          for (const currentCreatedSeries of createdSeries) {
            currentCreatedSeries.series.dots = currentCreatedSeries.newDots;
          }
          this.startSeriesTransitions(modifiedSeriesPathIds);
        }, 0);

        if (deletedPaths.length > 0) {
          setTimeout(() => {
            this.chartSeries = this.chartSeries.filter(
              (series) => !deletedPaths.includes(series.id)
            );
          }, 300);
        }
      })
    );
  }

  trackByAxis(index: number, plotDot: PlotDot) {
    return plotDot.axis;
  }

  trackById(index: number, element: ElementWithId) {
    return element.id;
  }

  /**
   * @description Starts transitions in the series
   */
  startSeriesTransitions(ids: number[]) {
    const duration = 300;

    const pathElements = this.pathElements.toArray();
    const pathBlurElements = this.pathBlurElements.toArray();
    const indexs = this.chartSeries
      .map((series, index) => ({ id: series.id, index }))
      .filter((series) => ids.includes(series.id))
      .map((series) => series.index);

    for (const index of indexs) {
      for (const path of [pathElements[index], pathBlurElements[index]]) {
        if (path) {
          const animate = document.createElementNS(
            "http://www.w3.org/2000/svg",
            "animate"
          );
          animate.setAttribute("attributeName", "d");
          animate.setAttribute("begin", "indefinite");
          animate.setAttribute("dur", duration + "ms");
          animate.setAttribute("from", this.chartSeries[index].oldPath);
          animate.setAttribute("to", this.chartSeries[index].path);
          animate.setAttribute("fill", "freeze");
          const el = path.nativeElement as Element;
          if (el.firstChild) {
            el.replaceChild(animate, el.firstChild);
          } else {
            el.appendChild(animate);
          }
          if (el.firstChild) (el.firstChild as SVGElement).beginElement();
        }
      }
    }
    setTimeout(() => this.updated.next(), duration);
  }

  /**
   * @description Rounds the number to the nearest value keeping at most one decimal place
   * @param number
   */
  roundNumber(number: number) {
    return Math.round(number * 10) / 10;
  }

  /**
   * Calculates which level is closest to the position
   * @param position the position
   * @param axis The level axis
   * @param numberOfAxis
   * @param maxLevel
   * @returns the closest level
   */
  getLevelCloser(
    position: Position,
    axis: number,
    numberOfAxis: number,
    maxLevel: number
  ): number | null {
    const axisAngle = -Math.PI / 2 + (Math.PI * 2 * axis) / numberOfAxis;
    let spaceArea = this.backgroundLevelSpace / 2;
    let closerDelta = Number.MAX_SAFE_INTEGER;
    let closerLevel: number | null = null;
    for (let i = 1; i <= maxLevel; i++) {
      const size = this.getRadiusOfLevel(i);
      const dotPosition = this.getCircleDot(size, axisAngle);
      const delta = Math.hypot(
        dotPosition.y - position.y,
        dotPosition.x - position.x
      );
      if (delta < spaceArea) return i;
      if (closerDelta > delta) {
        closerDelta = delta;
        closerLevel = i;
      }
    }
    return closerLevel;
  }

  /**
   * Get the points positions of the series
   * @param series
   * @param numberOfAxis
   */
  getDotPositions(series: number[], numberOfAxis: number) {
    const dots: Position[] = [];
    let angle = -Math.PI / 2;
    const deltaAngle = (Math.PI * 2) / numberOfAxis;
    for (const value of series) {
      const size = this.getRadiusOfLevel(value);
      dots.push(this.getCircleDot(size, angle));
      angle += deltaAngle;
    }
    return dots;
  }

  /**
   * @description generate background SVG path d
   * @param size level of background
   * @param numberOfAxis
   */
  getBackgroundChartFormat(size: number, numberOfAxis: number): string {
    let dots: Position[] = [];
    let angle = -Math.PI / 2;
    const deltaAngle = (Math.PI * 2) / numberOfAxis;
    for (let i = 0; i < numberOfAxis; i++) {
      dots.push(this.getCircleDot(size, angle));
      angle += deltaAngle;
    }
    return this.getPathFormat(dots);
  }

  /**
   * transform an array of points into a SVG path d
   * @param dots
   */
  getPathFormat(dots: Position[]): string {
    return "M " + dots.map((dot) => [dot.x, dot.y].join(",")).join(" L ") + "z";
  }

  /**
   * Returns the path of the axis line
   * @param dots
   */
  getAxisLine(
    axis: number,
    maxLevel: number,
    numberOfAxis: number
  ): string {
    let d = `M 0,0`;
    let angle = -Math.PI / 2;
    const deltaAngle = (Math.PI * 2) / numberOfAxis;
    const position = this.getCircleDot(
      maxLevel,
      angle + deltaAngle * axis
    );
    d += ` L ${position.x},${position.y}`;
    return d;
  }
}
