import { Injectable } from "@angular/core";
import { HttpErrorResponse, HttpClient } from "@angular/common/http";

import { Observable, throwError } from "rxjs";
import { catchError } from "rxjs/operators";
import { Answers, SaveResponse } from "./interfaces";

@Injectable()
export class QuestionsService {
  constructor(private http: HttpClient) {}

  saveAnswers(url: string, answers: Answers): Observable<SaveResponse> {
    return this.http
      .post<SaveResponse>(url, answers, {
        withCredentials: true,
      })
      .pipe(catchError(this.handleError));
  }

  private handleError(error: HttpErrorResponse) {
    try {
      if (error.error instanceof ErrorEvent) {
        // A client-side or network error occurred. Handle it accordingly.
        console.error("An error occurred:", error.error.message);
        return throwError("Something bad happened; please try again later.");
      } else {
        console.error(`Backend returned code ${error.status}, body` + error.error);
        return throwError(error.error.message);
      }
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong.
    } catch (err) {
      return throwError("Something bad happened; please try again later.");
    }
  }
}
