// -- copyright
// OpenProject is a project management system.
// Copyright (C) 2012-2018 the OpenProject Foundation (OPF)
//
// This program is free software; you can redistribute it and/or
// modify it under the terms of the GNU General Public License version 3.
//
// OpenProject is a fork of ChiliProject, which is a fork of Redmine. The copyright follows:
// Copyright (C) 2006-2013 Jean-Philippe Lang
// Copyright (C) 2010-2013 the ChiliProject Team
//
// This program is free software; you can redistribute it and/or
// modify it under the terms of the GNU General Public License
// as published by the Free Software Foundation; either version 2
// of the License, or (at your option) any later version.
//
// This program is distributed in the hope that it will be useful,
// MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
// GNU General Public License for more details.
//
// You should have received a copy of the GNU General Public License
// along with this program; if not, write to the Free Software
// Foundation, Inc., 51 Franklin Street, Fifth Floor, Boston, MA  02110-1301, USA.
//
// See doc/COPYRIGHT.rdoc for more details.

import { Injector, NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { OpenprojectCommonModule } from "core-app/modules/common/openproject-common.module";
import { OpenProjectHeaderInterceptor } from "core-app/modules/hal/http/openproject-header-interceptor";
import { HTTP_INTERCEPTORS, HttpClientModule } from "@angular/common/http";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { OrderByPositionPipe } from "./order-by-position.pipe";

import { RadarComponent } from "./charts/radar/radar.component";
import { BarsComponent } from "./charts/bars/bars.component";
import { PracticeVsPerformanceComponent } from "./charts/practice-vs-performance/practice-vs-performance.component";
import { LegendComponent } from "./charts/legend/legend.component";

import { IndicatorComponent } from "./indicator-component/indicator.component";

import {
  IndicatorWrapperComponent,
  selector as selectorIndicatorWrapperComponent,
} from "./indicator-wrapper-component/indicator-wrapper.component";

import {
  selector as selectorQuestions,
  QuestionsComponent,
} from "./questions-component/questions.component";

import {
  selector as selectorConsolidateAssessedsList,
  ConsolidateAssessedsListComponent,
} from "./consolidate-assesseds-list-component/consolidate-assesseds-list.component";

import {
  selector as selectorConsolidateDashboard,
  ConsolidateDashboardComponent,
} from "./consolidate-dashboard-component/consolidate-dashboard.component";

import {
  selector as selectorAssessmentsDashboard,
  AssessmentsDashboardComponent,
} from "./assessments-dashboard-component/assessments-dashboard.component";

import {
  selector as selectorAssessmentsDashboardFilter,
  AssessmentsDashboardFilterComponent,
} from "./assessments-dashboard-filter-component/assessments-dashboard-filter.component";


import { ConsolidateDashboardService } from "./consolidate-dashboard-component/consolidate-dashboard.service";
import { QuestionsService } from "./questions-component/questions.service";

import { HookService } from "core-app/modules/plugins/hook-service";

@NgModule({
  imports: [
    CommonModule,
    BrowserAnimationsModule,
    HttpClientModule,
    OpenprojectCommonModule,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: OpenProjectHeaderInterceptor,
      multi: true,
    },
    ConsolidateDashboardService,
    QuestionsService,
    OrderByPositionPipe,
  ],
  declarations: [
    // Declare the component for angular to use
    IndicatorWrapperComponent,
    OrderByPositionPipe,
    IndicatorComponent,
    QuestionsComponent,
    AssessmentsDashboardComponent,
    ConsolidateAssessedsListComponent,
    ConsolidateDashboardComponent,
    AssessmentsDashboardFilterComponent,
    RadarComponent,
    BarsComponent,
    PracticeVsPerformanceComponent,
    LegendComponent,
  ],
  entryComponents: [
    // Special case: Declare the component also as a bootstrap component
    // as it is being rendered from Rails.
    IndicatorWrapperComponent,
    IndicatorComponent,
    QuestionsComponent,
    AssessmentsDashboardComponent,
    ConsolidateAssessedsListComponent,
    ConsolidateDashboardComponent,
    AssessmentsDashboardFilterComponent,
  ],
})
export class PluginModule {
  constructor(injector: Injector) {
    const hookService = injector.get(HookService);
    // Explicitly bootstrap the survey component in the DOM if it is found
    // Angular would otherwise only bootstrap the global entry point bootstrap from the core
    // preventing us from using components like this survey component
    hookService.register("openProjectAngularBootstrap", () => [
      { selector: selectorQuestions, cls: QuestionsComponent },
      {
        selector: selectorConsolidateAssessedsList,
        cls: ConsolidateAssessedsListComponent,
      },
      {
        selector: selectorIndicatorWrapperComponent,
        cls: IndicatorWrapperComponent,
      },
      {
        selector: selectorConsolidateDashboard,
        cls: ConsolidateDashboardComponent,
      },
      {
        selector: selectorAssessmentsDashboard,
        cls: AssessmentsDashboardComponent,
      },
      {
        selector: selectorAssessmentsDashboardFilter,
        cls: AssessmentsDashboardFilterComponent,
      },
    ]);
  }
}
