import { Component, OnInit, Injector, ViewChildren, QueryList, ElementRef } from "@angular/core";
import { InjectField } from "core-app/helpers/angular/inject-field.decorator";
import { I18nService } from "core-app/modules/common/i18n/i18n.service";
import { ConfirmDialogService } from "core-components/modals/confirm-dialog/confirm-dialog.service";
import { GonService } from "core-app/modules/common/gon/gon.service";

interface ElementWithId {
  id: number;
}

interface Assessor {
  id: number;
  name: string;
  count: number;
}

type Assessed =
  | {
      status: "NotConsolidated";
      id: string;
      name: string;
      assessors: Assessor[];
    }
  | {
      status: "Consolidated";
      id: string;
      name: string;
      assessors: undefined;
    };

interface Group {
  id: number;
  name: string;
  count: number;
  assesseds: Assessed[];
}
type CheckboxStatus = "checked" | "unchecked" | "indeterminate";

type AssessedWithStatus = Assessed & {
  checkboxStatus: boolean;
  disabled: boolean;
};

interface GroupWithStatus extends Group {
  index: number;
  status: CheckboxStatus;
  assesseds: AssessedWithStatus[];
  disabled: boolean;
}

export const selector = "bm-consolidate-assesseds-list";

@Component({
  templateUrl: "./consolidate-assesseds-list.component.html",
  styleUrls: ["./consolidate-assesseds-list.component.sass"],
  selector,
})
export class ConsolidateAssessedsListComponent implements OnInit {
  @InjectField() I18n: I18nService;

  text: {
    button: string;
    title: string;
    description: string;
    consolidated: string;
  };

  groups: Group[];

  groupsWithStatus: GroupWithStatus[] = [];

  @ViewChildren("groupCheckbox") groupCheckboxs: QueryList<ElementRef>;

  consolidateURL: string;
  hashURL: string = "";
  buttonURL: string;

  constructor(readonly injector: Injector, readonly confirmDialog: ConfirmDialogService, readonly gon: GonService) {}

  ngOnInit(): void {
    this.groups = this.gon.get("groups") as Group[];
    this.consolidateURL = this.gon.get("consolidate_url") as string;

    this.text = {
      button: this.I18n.t("js.consolidate.button"),
      title: this.I18n.t("js.consolidate.title"),
      description: this.I18n.t("js.consolidate.description"),
      consolidated: this.I18n.t("js.consolidate.consolidated"),
    };

    this.groups.forEach((group, index) => {
      const assesseds = group.assesseds.map((assessed) => {
        const checkboxStatus =
          assessed.status === "Consolidated" || !assessed.assessors?.some((assossor) => group.count !== assossor.count);

        return {
          ...assessed,
          disabled: !checkboxStatus || assessed.status === "Consolidated",
          checkboxStatus,
        };
      });
      const groupWithStatus: GroupWithStatus = {
        ...group,
        index,
        assesseds,
        status: "unchecked",
        disabled: !assesseds.some((assessed) => assessed.checkboxStatus && !assessed.disabled),
      };
      groupWithStatus.status = this.getGroupCheckboxStatus(groupWithStatus);
      this.groupsWithStatus.push(groupWithStatus);
    });
    this.updateHash();
  }

  ngAfterViewInit() {
    this.groupsWithStatus.forEach((group) => {
      this.updateGroupCheckbox(group);
    });
  }

  getGroupCheckboxStatus(group: GroupWithStatus): CheckboxStatus {
    const count = group.assesseds.filter((assessed) => assessed.checkboxStatus).length;

    if (
      count === 0 ||
      (count === group.assesseds.filter((assessed) => assessed.checkboxStatus && assessed.disabled).length &&
        count !== group.assesseds.length)
    )
      return "unchecked";
    if (count === group.assesseds.length) return "checked";

    return "indeterminate";
  }

  updateHash() {
    const hashURL = `${this.groupsWithStatus
      .map((group) => {
        const assesseds = group.assesseds
          .filter((assessed) => assessed.checkboxStatus && !assessed.disabled)
          .map((assessed) => assessed.id)
          .join(",");
        if (!assesseds) return "";
        return `g${group.id}=${assesseds}`;
      })
      .filter((group) => group)
      .join("&")}`;

    if (hashURL) this.buttonURL = this.consolidateURL + "#!" + hashURL;
    else this.buttonURL = "";
  }

  updateGroupCheckbox(group: GroupWithStatus) {
    group.status = this.getGroupCheckboxStatus(group);
    const element = this.groupCheckboxs.toArray()[group.index]?.nativeElement as HTMLInputElement | undefined;

    if (element) {
      switch (group.status) {
        case "unchecked":
          element.indeterminate = false;
          element.checked = false;
          break;
        case "indeterminate":
          element.indeterminate = true;
          break;
        case "checked":
          element.indeterminate = false;
          element.checked = true;
          break;
      }
    }
  }

  updateButton(group: GroupWithStatus) {
    group.status = this.getGroupCheckboxStatus(group);
    const element = this.groupCheckboxs.toArray()[group.index]?.nativeElement as HTMLInputElement | undefined;

    if (element) {
      switch (group.status) {
        case "unchecked":
          element.indeterminate = false;
          element.checked = false;
          break;
        case "indeterminate":
          element.indeterminate = true;
          break;
        case "checked":
          element.indeterminate = false;
          element.checked = true;
          break;
      }
    }
  }

  onAssessedCheckboxClick(event: MouseEvent | TouchEvent, assessed: AssessedWithStatus, group: GroupWithStatus) {
    if (assessed.disabled) {
      return event.preventDefault();
    }
    assessed.checkboxStatus = !assessed.checkboxStatus;
    const element = event.target as HTMLInputElement;
    element.checked = assessed.checkboxStatus;
    this.updateHash();
    this.updateGroupCheckbox(group);
  }

  onGroupCheckboxClick(group: GroupWithStatus) {
    if (group.status === "unchecked") {
      for (const assessed of group.assesseds) {
        if (!assessed.disabled) assessed.checkboxStatus = true;
      }
    } else {
      for (const assessed of group.assesseds) {
        if (!assessed.disabled) assessed.checkboxStatus = false;
      }
    }
    this.updateHash();
    this.updateGroupCheckbox(group);
  }

  trackById(index: number, item: ElementWithId) {
    return item.id;
  }
}
